import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import {
  deletePerkByUser,
  getAllPerkByUser,
} from "../../services/perk.services";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import { ToastContainer, toast } from "react-toastify";

interface Perk {
  name: string;
  assign: string;
}

interface Props {
  setActiveTab: (tab: string) => void;
  perks: any;
}

/**
 * The ViewallPerk component displays a list of perks.
 * Each perk has a name and an assigned person.
 * The user can edit or delete each perk.
 */
const ViewallPerk: React.FC<Props> = ({ setActiveTab, perks }) => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [getAllPerk, setAllPerk] = useState<null | any[]>(null);
  const [selectedUser, setSelectedUser] = useState<null>(null);
  const [selectedPerk, setSelectedPerk] = useState<any>(null);
  const [total, setTotal] = useState<any>("");
  const handleClose = () => {
    setShow(false);
    setSelectedPerk(null);
  };
  const handleShow = (perk: any) => {
    setSelectedPerk({
      userId: perk.account.id,
      perkId: perk.perk.id,
    });
    setShow(true);
  };

  useEffect(() => {
    if (selectedUser) {
      getAllPerkByUser(page, selectedUser).then((res: any) => {
        setAllPerk(res.data.data);
        setTotal(res?.data.total);
      });
    }
  }, [page, selectedUser, selectedPerk]);

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };

  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  const handleDeletePerk = () => {
    deletePerkByUser(selectedPerk)
      .then(() => {
        toast.success("Deleted Successfully!!");
        handleClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <>
      <ToastContainer />
      <div className=" max-w-md m-auto py-3">
        <AutoCompleteCommon
          listData={
            perks.length > 0
              ? perks?.map((perk: any) => ({
                  value: perk.id,
                  name: perk.email,
                }))
              : []
          }
          value={selectedUser}
          placeholder="Select User"
          selectedDropDownVal={(val: any) => setSelectedUser(val)}
        />
      </div>

      {getAllPerk && selectedUser && (
        <>
          {getAllPerk.map((perk, index) => (
            <div className=" section-box  my-5">
              <div className="flex justify-end items-center space-x-2">
                <Link to="#" onClick={() => setActiveTab("first")}>
                  <Icon icon="bx:edit" />
                </Link>
                <Link onClick={() => handleShow(perk)} to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </div>
              <div className="all-prompts-detail" key={index}>
                <div className="flex items-start mb-3">
                  <h5>Title:</h5>
                  <p>{perk?.perk?.title ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>provider:</h5>
                  <p>{perk?.perk?.provider ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Model:</h5>
                  <p>{perk?.perk?.model ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Rule:</h5>
                  <p>{perk?.perk?.rule ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Rule Value:</h5>
                  <p>{perk?.perk?.ruleValue ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Activate On Registration:</h5>
                  <p>{perk?.perk?.activateOnRegistration ? "Yes" : "No"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Status:</h5>
                  <p>{perk?.status ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-3">
                  <h5>Updated At:</h5>
                  <p>
                    {" "}
                    {perk?.updatedAt
                      ? new Date(perk?.updatedAt).toLocaleString()
                      : "Not Specified"}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <div className="pagination">
            <button
              className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
              disabled={page === 1}
              onClick={() => previous()}
            >
              {"<"}
            </button>
            <>{PagePagination()}</>
            <button
              className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
              disabled={page === TotalLength}
              onClick={() => next()}
            >
              {">"}
            </button>
          </div>
        </>
      )}
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={handleDeletePerk}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewallPerk;
