import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import {
  getAllGlobalProduct,
  getAllProducts,
} from "../../services/pack.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

const ViewAllPacks = ({ eventType, packHandler, type }: any) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [packs, setPacks] = useState([]);
  const [page, setPage] = useState(1);
  const [allGlobal, setAllGLobal] = useState<any>([]);
  const [total, setTotal] = useState<any>("");

  function convertDateToDays(targetDate: any) {
    const currentDate: any = new Date();
    const timeDifference = targetDate - currentDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return Math.floor(daysDifference);
  }

  useEffect(() => {
    getAllData();
  }, [eventType, page, type]);

  function getAllData() {
    getAllProducts(eventType, page, type)
      .then((result: any) => {
        setPacks(result?.data.data);
        setTotal(result.data.total);
        getAllGlobalProduct()
          .then((res: any) => {
            setAllGLobal(res?.data.data);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength?.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  console.log(packs, "check pack");

  return (
    <>
      <div className="custom-search mb-5">
        <input type="text" placeholder="Search" />
        <Link to="#">
          <img alt="image-search" src="./assets/image/search.svg" />
        </Link>
      </div>
      <>
        {packs.map((pack: any) => (
          <div className="section-box perk-credit-box perk-credit-box-padding">
            <div className="flex justify-between items-center mb-2">
              <h4 className="max-w-max">{pack?.title}</h4>
              <div className="flex items-center gap-2 pe-3">
                <Link to="#" onClick={() => packHandler(pack?.id)}>
                  <Icon icon="bx:edit" />
                </Link>
                {/* <Link to="#" onClick={handleShow}>
                                    <Icon icon="material-symbols:delete-outline" />
                                </Link> */}
              </div>
            </div>
            <h4 className="mb-4">{pack?.price && `$${pack?.price}`}</h4>
            {(pack?.type || pack?.type !== "") && (
              <h5 className=" text-end">
                {pack?.type === "apple" ? "[Apple]" : "[Google]"}
              </h5>
            )}
            <div className="creditpack-detail">
              <div className="flex items-start mb-4 gap-4">
                <h5>Expiry:</h5>
                <p className="park-description">
                  {pack?.expiresAt &&
                    `Expires in ${convertDateToDays(
                      new Date(pack.expiresAt)
                    )} days from ${eventType}`}
                </p>
              </div>
              <div className="flex items-start mb-4 gap-4">
                <h5>No. of credits:</h5>
                <p>50</p>
              </div>
              <div className="flex items-start mb-4 gap-4">
                <h5>Description:</h5>
                <p className="park-description">{pack?.description}</p>
              </div>
            </div>
            <>
              {allGlobal?.length > 0 &&
                allGlobal.map(
                  (item: any) =>
                    item.productId === pack?.productId && (
                      <div
                        style={{
                          borderRadius: "10px",
                          border: "0.1px solid #000",
                          background: "#FFF",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          padding: "20px 40px",
                          margin: "40px 0",
                        }}
                      >
                        <div className="creditpack-detail">
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Title:</h5>
                            <p className="park-description">{item?.title}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Description:</h5>
                            <p>{item?.description}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Chat Provider:</h5>
                            <p className="park-description">{item?.provider}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Model:</h5>
                            <p>{item?.model}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Rank:</h5>
                            <p>{item?.rank}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Credits:</h5>
                            <p>{item?.credits}</p>
                          </div>
                          <div className="flex items-start mt-3 gap-4">
                            <h5>Prompt Size:</h5>
                            <p>{item?.promptSize}</p>
                          </div>
                        </div>
                      </div>
                    )
                )}
            </>
          </div>
        ))}
      </>
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={handleClose}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewAllPacks;
