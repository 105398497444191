import { useEffect, useMemo, useState } from "react";
import { ICategories } from "../../api/interfaces/categories";
import AddSystemPromptCategories from "../../component/AddSystemPromptCategories/AddSystemPromptCategories";
import ViewSystemPromptCategories from "../../component/ViewSystemPromptCategories/ViewSystemPromptCategories";
import { getCategories } from "../../services/categories.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * Component for managing system prompt categories.
 * @component
 * @returns {JSX.Element} SystemPromptCategories component
 */
const SystemPromptCategories = () => {
  const [activeTab, setActiveTab] = useState("first");
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const handleTab = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (activeTab === "first") {
      getCategories().then((res: any) => {
        setCategories(res?.data.data);
      });
    }
  }, [activeTab]);

  return (
    <div className="Homepagecontent">
      <Tabs
        onValueChange={(val) => {
          setActiveTab(val);
          setEditId("");
        }}
        value={activeTab}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add system prompt</TabsTrigger>
          <TabsTrigger value="second">View system prompts</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <AddSystemPromptCategories editId={editId} setEditId={setEditId} />{" "}
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <ViewSystemPromptCategories
            categories={categories}
            setEditId={setEditId}
            setActiveTab={setActiveTab}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SystemPromptCategories;
