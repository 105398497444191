import { useFormContext, Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
export default function FormTextField({
  name,
  label,
  type,
  textInputCss,
  onBlurHandler = () => {},
  startEndadornment,
  outerDivStyle,
  onChange = () => {},
  endEndadornment,
  ref,
  errorStyle,
  labelCss,
  ...other
}: any) {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <div className="custom-form">
            {label && (
              <label
                className={`${!!error ? "text-error" : ""} ${labelCss}`}
                htmlFor={name}
              >
                {label}
              </label>
            )}
            <input
              className={cn(
                `w-full ${
                  !!error
                    ? "focus:border-error border-2 border-error"
                    : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5]"
                } ${textInputCss}`
              )}
              type={type}
              {...field}
              {...other}
              onBlur={(e) => {
                trigger(name);
                onBlurHandler();
              }}
              onChange={(e) => {
                field.onChange(e);
                trigger(name);
                onChange(e);
              }}
            />
          </div>
          {/* {!!error && (
            <h3 className={"text-f12 text-error "}>{error?.message}</h3>
          )} */}
        </>
      )}
    />
  );
}
