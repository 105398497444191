import axios, { AxiosError, AxiosResponse } from "axios";

import { getToken } from "../RequireAuth";

const baseUrl = process.env.REACT_APP_BASE_URL;
export const baseURL = baseUrl;
const authToken = async () => `Bearer ${await getToken()}`;

const http = (url: string, type: string, body?: any, customHeader?: any) => {
  return new Promise(async (resolve, reject) => {
    axios
      .request({
        url: `${baseURL}${url}`,
        method: type,
        headers: {
          Authorization: await authToken(),
          "Content-type": customHeader
            ? "multipart/form-data"
            : "application/json",
        },
        data: body,
      })
      .then((res: AxiosResponse) => {
        resolve(res);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
};

export default http;
