import { useEffect, useMemo, useState } from "react";
import AddChatProvider from "../../component/AddChatProvider/AddChatProvider";
import ViewChatProvider from "../../component/ViewChatProvider/ViewChatProvider";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";

interface ChatProvider {
  // Define the properties of a chat provider
  // Add the necessary types for each property
  providerName: string;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ChatProvider: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");

  /**
   * Fetches data from localStorage and updates the state with the fetched data.
   */

  /**
   * Handles the tab change event and updates the active tab state.
   * Also fetches data from localStorage when the tab changes.
   * @param tab - The tab key of the clicked tab
   */
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // Initial data fetch when component mounts

  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="first" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a Provider</TabsTrigger>
          <TabsTrigger value="second">View all Providers</TabsTrigger>
        </TabsList>
        <TabsContent value="first">
          <AddChatProvider />
        </TabsContent>
        <TabsContent value="second">
          <ViewChatProvider />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default ChatProvider;
