import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import {
  createCharacterQuestionPool,
  getAllCharacter,
  getAllCharacterQuestionsPool,
  updateCharacterQuestionsPool,
} from "../../services/character.services";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import ErrorMessage from "../../common/ErrorMessage";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Questions {
  name: string;
  characterId: string;
  questions: string[];
}

interface IProps {
  editId: string;
  setEditId: (id: string) => void;
}

const AddCharacterQuestion: React.FC<IProps> = ({ editId, setEditId }) => {
  const [character, setCharacter] = useState<Partial<IAICharacters[]>>([]);
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);
  // const [category, setCategory] = useState<Partial<ICategories[]>>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [addedQuestion, setAddedQuestion] = useState<any[]>([]);

  // react hook form
  useEffect(() => {
    getAllCharacter().then((res: any) => {
      setCharacter(res?.data.data);
    });
  }, []);
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(""),
        characterId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      name: "",
      characterId: "",
      questions: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  // react hook form

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const onHandleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
  //   setQuestion(e.target.value);
  // };
  // const handleChange = (
  //   event: React.ChangeEvent<
  //     HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement
  //   >
  // ) => {
  //   const { name, value } = event.target;
  //   setQuestions((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const onAddQuestion = () => {
    setAddedQuestion((prev: any) => [...prev, getValues("questions")]);
    setValue("questions", "");
    toast.success("Added Character Question successfully!");
  };
  // const onAddQuestion = () => {
  //   setQuestions((prev) => ({
  //     ...prev,
  //     questions: [...prev?.questions, question],
  //   }));
  //   setQuestion("");
  //   toast.success("Added Character Question successfully!");
  // };
  const onRemoveQuestion = () => {
    if (removeIndex !== null) {
      const updatedQuestions = addedQuestion.filter(
        (_, index) => index !== removeIndex
      );
      setAddedQuestion(updatedQuestions);
      handleClose();
    }
  };

  const onDelete = (index: number) => {
    setRemoveIndex(index);
    handleShow();
  };

  const onEdit = (index: number) => {
    const findQuestion = addedQuestion.find((_, key) => key === index);
    const filterQuestion = addedQuestion.filter((_, key) => key !== index);

    setValue("questions", findQuestion as string);
    setAddedQuestion(filterQuestion);
  };

  const onQuestion = (credential: any) => {
    if (addedQuestion.length > 0) {
      if (editId) {
        updateCharacterQuestionsPool(editId, {
          _id: editId,
          ...credential,
          questions: addedQuestion,
        })
          .then(() => {
            setEditId("");
            reset();
            setAddedQuestion([]);
            toast.success("Updated Question Pool successfully!");
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      } else {
        createCharacterQuestionPool({
          ...credential,
          questions: addedQuestion,
        })
          .then(() => {
            toast.success("Created Question Pool successfully!");
            reset();
            setAddedQuestion([]);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }
    } else {
      setErrorMessage("At least One Question is required for Pool");
    }
    // const name = questions.name;
    // const character = questions.characterId;
    // const question = questions.questions;
    // if (name !== "") {
    //   if (question.length > 0) {
    //     if (editId) {
    //       updateCharacterQuestionsPool(editId, questions)
    //         .then(() => {
    //           toast.success("Updated Question Pool successfully!");
    //           setQuestion("");
    //           setQuestions({
    //             name: "",
    //             characterId: "",
    //             questions: [],
    //           });
    //         })
    //         .catch((err) => {
    //           toast.error(
    //             `${name !== "" && "Name"} ${
    //               question?.length === 0 && "Questions"
    //             } ${character !== "" && "character"} Required!!`
    //           );
    //           console.log(err);
    //         });
    //     } else {
    //       createCharacterQuestionPool(questions)
    //         .then(() => {
    //           toast.success("Created Question Pool successfully!");
    //           setQuestion("");
    //           setQuestions({
    //             name: "",
    //             characterId: "",
    //             questions: [],
    //           });
    //         })
    //         .catch((err) => {
    //           toast.error(
    //             `${name === "" ? "Name" : ""} ${
    //               question?.length === 0 ? "Questions" : ""
    //             } ${character === "" ? "character" : ""} Required!!`
    //           );
    //           console.log(err);
    //         });
    //     }
    //   } else {
    //     toast.error("Question is Required");
    //   }
    // } else if (question.length > 0) {
    //   if (name !== "") {
    //     if (editId) {
    //       updateCharacterQuestionsPool(editId, questions)
    //         .then(() => {
    //           toast.success("Updated Question Pool successfully!");
    //           setQuestion("");
    //           setEditId("");
    //           setQuestions({
    //             name: "",
    //             characterId: "",
    //             questions: [],
    //           });
    //         })
    //         .catch((err) => {
    //           toast.error(
    //             `${name !== "" && "Name"} ${
    //               question?.length === 0 && "Questions"
    //             } ${character !== "" && "character"} Required!!`
    //           );
    //           console.log(err);
    //         });
    //     } else {
    //       createCharacterQuestionPool(questions)
    //         .then(() => {
    //           toast.success("Created Question Pool successfully!");
    //           setQuestion("");
    //           setQuestions({
    //             name: "",
    //             characterId: "",
    //             questions: [],
    //           });
    //         })
    //         .catch((err) => {
    //           toast.error(
    //             `${name !== "" && "Name"} ${
    //               question?.length === 0 && "Questions"
    //             } ${character !== "" && "character"} Required!!`
    //           );
    //           console.log(err);
    //         });
    //     }
    //   } else {
    //     toast.error("Name is Required");
    //   }
    // } else {
    //   toast.error("All Field are Required");
    // }
  };
  useEffect(() => {
    if (editId && character.length > 0) {
      getAllCharacterQuestionsPool().then((res: any) => {
        const findQuestions = res?.data?.data.find(
          (item: any) => item._id === editId
        );

        // setQuestions(findQuestions);
        setValue("characterId", findQuestions?.characterId);
        setValue("name", findQuestions?.name);
        setValue("questions", findQuestions?.questions[0] as string);
        setAddedQuestion(findQuestions?.questions);
      });
    }
  }, [editId, character]);
  // useEffect(() => {
  //   if (editId && character.length > 0) {
  //     getAllCharacterQuestionsPool().then((res: any) => {
  //       const findQuestions = res?.data.data.find(
  //         (item: any) => item._id === editId
  //       );
  //       setQuestions({ ...findQuestions });
  //     });
  //   }
  // }, [editId, character]);

  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(onQuestion)}>
        <div className="max-50 space-y-6">
          <AutoCompleteCommon
            error={errors?.characterId}
            label="Choose Character:"
            listData={character?.map((item) => ({
              name: item?.name,
              value: item?._id,
            }))}
            value={getValues("characterId")}
            // value={{ value: getValues("characterId") as String }}
            placeholder="Choose a Character"
            selectedDropDownVal={(val: any) => setValue("characterId", val)}
          />
          <FormTextAreaField
            placeholder="Select pool name"
            name="name"
            label="Question Pool Name:"
            className="h-[80px]"
          />
          <FormTextAreaField
            placeholder="add questions"
            name="questions"
            label="Add question for the pool:"
            className="h-[130px]"
          />
          <div className=" flex justify-end ">
            <button
              type="button"
              className=" border border-black max-w-[100px] text-black h-[45px] text-f18 rounded-[10px] font-medium w-full"
              onClick={onAddQuestion}
            >
              Add
            </button>
          </div>
          <div className="questionaddwrap">
            {addedQuestion.length > 0 &&
              addedQuestion.map((question: any, index: any) => (
                <div className="questionbox" key={index}>
                  <p>{question}</p>
                  <span>
                    <Link to="#">
                      <Icon icon="bx:edit" onClick={() => onEdit(index)} />
                    </Link>
                    <Link onClick={() => onDelete(index)} to="#">
                      <Icon icon="material-symbols:delete-outline" />
                    </Link>
                  </span>
                </div>
              ))}
          </div>
          <ErrorMessage
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <button className="btn-black mt-5" onClick={onQuestion}>
            {editId ? "Update Question Pool" : " Create Question Pool"}
          </button>
        </div>
        <ToastContainer />
      </FormProvider>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddCharacterQuestion;
