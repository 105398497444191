import { useFormContext, Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
export default function FormSelectField({
  name,
  label,
  type,
  className,
  onBlurHandler = () => {},
  startEndadornment,
  outerDivStyle,
  onChange = () => {},
  endEndadornment,
  ref,
  option,
  placeholder = "Select",
  errorStyle,
  labelCss,
  ...other
}: any) {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <div className="custom-form">
          {label && (
            <label
              className={`${!!error ? "text-error" : ""} ${labelCss}`}
              htmlFor={name}
            >
              {label}
            </label>
          )}
          <select
            className={cn(
              `w-full ${
                !!error
                  ? "focus:border-error border-2 border-error rounded-[8px] p-[18px] text-f18 font-semibold"
                  : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5] form-select"
              } ${className}`
            )}
            aria-label="Default select example"
            {...field}
            {...other}
            onBlur={(e) => {
              trigger(name);
              onBlurHandler();
            }}
            onChange={(e) => {
              field.onChange(e);
              trigger(name);
              onChange(e);
            }}
          >
            <option value="">{placeholder}</option>
            {option.length > 0 &&
              option.map((item: any, index: number) => (
                <option key={index} value={item.value}>
                  {item.name}
                </option>
              ))}
          </select>
          {/* {!!error && (
            <h3 className={"text-f12 text-error"}>{error?.message}</h3>
          )} */}
        </div>
      )}
    />
  );
}
