import http from "../http/request";

function getCategories() {
  return new Promise((resolve, reject) => {
    http(`/categories`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getCategoriesBylimit(page: number,search:any) {
  return new Promise((resolve, reject) => {
    http(`/categories?page=${page}&limit=5${search?`&search=${search}`:''}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateIndividualCategory(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/categories/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function addCategory(body: any) {
  return new Promise((resolve, reject) => {
    http(`/categories`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deleteIndividualCategory(id: string) {
  return new Promise((resolve, reject) => {
    http(`/categories/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function createCategoryQuestionsPool(body: any) {
  return new Promise((resolve, reject) => {
    http(`/categories-questions-pool`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getAllCategoryQuestionsPool() {
  return new Promise((resolve, reject) => {
    http(`/categories-questions-pool`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getCategoryQuestionsByLimit(page: number, categoryId?:string, search?:string ) {
  return new Promise((resolve, reject) => {
    http(`/categories-questions-pool?page=${page}&limit=5${categoryId&&`&categoryId=${categoryId}`}${search&&`&search=${search}`}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deleteCategoryQuestionsPool(id: string) {
  return new Promise((resolve, reject) => {
    http(`/categories-questions-pool/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateCategoryQuestionsPool(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/categories-questions-pool/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function addLinkCategorytoPool(categoryId: string, poolId: string) {
  return new Promise((resolve, reject) => {
    http(
      `/categories-questions-pool/link-pool-to-category?categoryId=${categoryId}&poolId=${poolId}`,
      "post",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  getCategories,
  getCategoriesBylimit,
  updateIndividualCategory,
  addCategory,
  deleteIndividualCategory,
  createCategoryQuestionsPool,
  getAllCategoryQuestionsPool,
  getCategoryQuestionsByLimit,
  deleteCategoryQuestionsPool,
  updateCategoryQuestionsPool,
  addLinkCategorytoPool,
};
