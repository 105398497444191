import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import {
  addCharacter,
  updateCharacterById,
} from "../../services/character.services";
import { getTags } from "../../services/tags.services";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import * as Yup from "yup";
import FormSelectField from "../../common/hook-form/FormSelectField";
import AutoCompleteCommon from "../../common/CommonAutoComplete";

interface Props {
  isNew: boolean;
  editCharacter: IAICharacters | null;
}

const AddCharacter: React.FC<Props> = ({ isNew, editCharacter }) => {
  // const [character, setCharacter] = useState<any>({
  //   name: "",
  //   description: "",
  //   logo: "",
  //   tags: [],
  // });
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState("");
  const [editId, setEditId] = useState<string | null>(null);
  // react hook form provider

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(""),
        description: Yup.string().required(""),
        tags: Yup.string().required(""),
        logo: Yup.string().required(""),
      })
    ),
    defaultValues: {
      _id: "",
      name: "",
      description: "",
      tags: "",
      logo: "",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
    getValues,
  } = methods;

  useEffect(() => {
    getTags()
      .then((res: any) => {
        setTags(
          res?.data.data?.map((item: any) => {
            return { name: item, value: item };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    if (isNew === true) {
      reset();
      setSelectedTags([]);
      setEditId(null);
    }
    if (editCharacter) {
      setValue("_id", editCharacter._id);
      setValue("name", editCharacter.name);
      setValue("description", editCharacter.description);
      setValue("tags", editCharacter?.tags[0]);
      setValue("logo", editCharacter.logo);
      setSelectedTags(editCharacter?.tags);
      setEditId(editCharacter._id);
    }
  }, [editCharacter, isNew]);

  useEffect(() => {
    setImagePreview(getValues("logo"));
  }, [watch("logo")]);

  const handleAdd = (formData: any) => {
    if (!editId) {
      const addData = {
        name: formData.name || "",
        description: formData.description || "",
        logo: formData.logo || "",
        tags: selectedTags || [],
      };
      addCharacter(addData)
        .then(() => {
          reset();
          setSelectedTags([]);
          toast.success("Add Character Successful!!");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      updateCharacterById(formData._id, { ...formData, tags: selectedTags })
        .then(() => {
          setSelectedTags([]);
          reset();
          setEditId(null);
          toast.success("character Updated");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
  const onDelete = (index: string) => {
    const filterTag = selectedTags.filter((tag: any) => tag !== index);
    setSelectedTags(filterTag);
  };
  const tagHandler = (value: any) => {
    const findTag = selectedTags.find((tag: any) => tag === value);
    if (findTag === undefined) {
      setValue("tags", value);
      value && trigger("tags");
      setSelectedTags((item: any) => [...item, value]);
    }
  };
  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6">
          <FormTextField
            placeholder="enter here name"
            name="name"
            label="Name:"
          />
          <ul className="link-question-list">
            {selectedTags.length > 0 &&
              selectedTags.map(
                (tag: any) =>
                  tag && (
                    <li key={tag}>
                      {tag}
                      <Link onClick={() => onDelete(tag)} to="#">
                        <Icon icon="carbon:close-outline" />
                      </Link>
                    </li>
                  )
              )}
          </ul>
          {/* <FormSelectField name="tags" label="Tags:" option={tags} /> */}
          <AutoCompleteCommon
            error={errors?.tags}
            label="Tags:"
            listData={tags}
            value={getValues("tags")}
            // defaultValue={{ value: getValues("tags") }}
            placeholder="Select"
            selectedDropDownVal={(val: any) => tagHandler(val)}
          />
          <FormTextAreaField
            className="h-[100px]"
            name="description"
            placeholder="please enter description"
            label="Description:"
          />
          <div className="flex space-x-3">
            <div className=" w-full self-center	">
              <FormTextField
                name="logo"
                label="Image URL:"
                placeholder="Paste the image URL here"
              />
            </div>
            {imagePreview !== "" && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxWidth: "100px", marginTop: "10px" }}
              />
            )}
          </div>
          <button className="btn-black mt-5" type="submit">
            {editId ? "Update" : "Add"}
          </button>
        </div>
        <ToastContainer />
      </FormProvider>
    </>
  );
};

export default AddCharacter;
