import { useEffect, useMemo, useState } from "react";
import AddCategory from "../../component/AddCategory/AddCategory";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { getUsers, updateUserType } from "../../services/user.services";
import * as Yup from "yup";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
import { useForm } from "react-hook-form";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import { addCreditItemToUser } from "../../services/perk.services";
import { toast, ToastContainer } from "react-toastify";
import FormSelectField from "../../common/hook-form/FormSelectField";
import ViewAllTestAccount from "../../component/viewAllTestAccount/viewAllTestAccount";

const TestAccountSetup: React.FC = () => {
  const [users, setUsers] = useState([]);

  useEffect(() => {
    getUsers().then((res: any) => {
      setUsers(res?.data.data);
    });
  }, []);

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        account_type: Yup.string().required(""),
        userId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      account_type: "",
      userId: "",
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = methods;
  const handleAddCredits = (formData: any) => {
    const userId = formData.userId;
    updateUserType(userId, formData?.account_type)
      .then((res) => {
        toast.success("Account Type Changed Successfully");
        reset();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="addCategory" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="addCategory">Test Account</TabsTrigger>
          <TabsTrigger value="viewTestAccount">
            View All Test Account
          </TabsTrigger>
        </TabsList>
        <TabsContent value="addCategory">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(handleAddCredits)}
          >
            <div className="max-50 space-y-6">
              <AutoCompleteCommon
                name="userId"
                error={errors.userId}
                label="Choose a User:"
                listData={
                  users.length > 0
                    ? users?.map((user: any) => ({
                        value: user?.id,
                        name: user?.username,
                      }))
                    : []
                }
                value={getValues("userId")}
                placeholder="Choose a User"
                selectedDropDownVal={(val: any) => {
                  setValue("userId", val);
                  trigger("userId");
                }}
              />
              <FormSelectField
                placeholder="Choose Account Type"
                label="Account Type :"
                name="account_type"
                option={[
                  { name: "Test", value: "test" },
                  { name: "User", value: "user" },
                ]}
              />
              <button className="btn-black mt-5" type="submit">
                Update
              </button>
            </div>
            <ToastContainer />
          </FormProvider>
        </TabsContent>
        <TabsContent value="viewTestAccount">
          <ViewAllTestAccount />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default TestAccountSetup;
