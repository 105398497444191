import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { deleteTags, getTags } from "../../services/tags.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Tag {
  tag: string;
}

interface Props {
  setEditId: (id: string) => void;
  handleTabChange: (tab: string) => void;
}

/**
 * The Viewtags component displays a list of tags.
 * The user can search for tags, edit or delete each tag,
 * and sort the tags alphabetically.
 */
const Viewtags: React.FC<Props> = ({ handleTabChange, setEditId }) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string>("");
  const [tags, setTags] = useState<string[]>([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onEditTag = (tag: string) => {
    setEditId(tag);
    handleTabChange("first");
  };

  const onRemoveQuestion = () => {
    deleteTags({ tag: removeIndex })
      .then(() => {
        getAllTags();
      })
      .catch((err) => {
        console.log(err);
      });
    handleClose();
  };

  const onDelete = (tag: string) => {
    setRemoveIndex(tag);
    handleShow();
  };

  function getAllTags() {
    getTags()
      .then((res: any) => {
        setTags(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getAllTags();
  }, []);
  return (
    <>
      <div className="max-50 view-content tags-main">
        <ol>
          {tags?.length > 0 &&
            tags?.map((tag, index) => (
              <li key={index}>
                <p>{tag}</p>
                <span>
                  <Link to="#" onClick={() => onEditTag(tag)}>
                    <Icon icon="bx:edit" />
                  </Link>
                  <Link onClick={() => onDelete(tag)} to="#">
                    <Icon icon="material-symbols:delete-outline" />
                  </Link>
                </span>
              </li>
            ))}
        </ol>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
      s
    </>
  );
};

export default Viewtags;
