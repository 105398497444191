import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { updateIndividualFeedback } from "../../services/feedback.services";
import ViewAllFeedback from "../ViewAllFeedback/ViewAllFeedback";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";

interface IProps {
  editId: string;
  setEditId: (id: string) => void;
  feedback: any;
  setFeedback: (id: string) => void;
  role: string;
  type: string;
  setViewAll: (id: boolean) => void;
  viewAll: boolean;
  handleFeedback: (feed: any) => void;
}
const IndividualHistory: React.FC<IProps> = ({
  editId,
  setEditId,
  feedback,
  setFeedback,
  role,
  type,
  setViewAll,
  viewAll,
  handleFeedback,
}) => {
  const [edit, setEdit] = useState(false);

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        answer: Yup.string().required(""),
      })
    ),
    defaultValues: {
      feedback: feedback?.feedback ?? "",
      answer: feedback?.answer ?? "",
    },
  });

  const { handleSubmit, reset } = methods;

  const handleAdd = (formData: any) => {
    updateIndividualFeedback(editId, { answer: formData?.answer })
      .then((res: any) => {
        toast.success("Answer Submit Successful!!");
        setEditId("");
        setFeedback("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   if (editId) {
  //     setViewAll(true);
  //   }
  // }, [editId]);

  const handleClick = () => {
    setViewAll(true);
  };
  if (viewAll) {
    return (
      <ViewAllFeedback
        editId={editId}
        setViewAll={setViewAll}
        setEdit={setEdit}
        setEditId={setEditId}
        role={role}
        type={type}
        handleFeedback={handleFeedback}
      />
    );
  }
  return (
    <>
      <ToastContainer />
      {!edit && (
        <div className="flex justify-end pb-3">
          <Link to="#" onClick={() => setEdit(true)}>
            <Icon icon="bx:edit" fontSize={"20px"} />
          </Link>
        </div>
      )}
      <div
        style={{
          borderRadius: "10px",
          border: "0.25px solid #fff",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          padding: "40px 25px",
          position: "relative",
        }}
      >
        <span className="flex justify-end">
          {feedback ? feedback?.createdAt.slice(0, 10) : ""}
        </span>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
          <div className="max-50 space-y-4">
            <FormTextAreaField
              name="feedback"
              label={feedback?.user?.username ?? "" + ":"}
              placeholder="Enter the title"
              className="h-[140px] "
              readOnly
            />
            <FormTextAreaField
              name="answer"
              label="Team Response:"
              placeholder="Enter your Response here..."
              className="h-[180px] "
            />
          </div>
          {edit && (
            <button className="btn-black mt-5" type="submit">
              Send Response
            </button>
          )}
        </FormProvider>
      </div>
      <div className="text-end mt-5">
        <h5 className="underline">
          <u style={{ cursor: "pointer" }} onClick={handleClick}>
            View All Feedback from {feedback?.user?.username ?? ""}
          </u>
        </h5>
      </div>
    </>
  );
};

export default IndividualHistory;
