export const ItemsData = [
  { id: 1, name: "Categories", link: "/categories" },
  { id: 2, name: "AI Characters", link: "/character" },
  { id: 3, name: "Questions for categories", link: "/questionCategories" },
  { id: 4, name: "Questions for AI characters", link: "/questionCharacter" },
  { id: 5, name: "Link question pools to categories", link: "/linkQuestion" },
  {
    id: 6,
    name: "Link question pools to AI characters",
    link: "/linkQuestionCharacter",
  },
  {
    id: 7,
    name: "Add or Create Suggested Question Pool",
    link: "/add-suggested-question-pool",
  },
  {
    id: 8,
    name: "Link Suggested Question Pool",
    link: "/link-suggested-question-pool",
  },
  { id: 9, name: "Suggested Boardrooms", link: "/suggestedboardrooms" },
  { id: 10, name: "Credit Packs", link: "/CreditPack" },
  { id: 11, name: "Tags for AI characters", link: "/tag" },
  { id: 12, name: "Type of perk", link: "/perkType" },
  { id: 13, name: "Add User To Perks", link: "/perkPage" },
  { id: 14, name: "Feedbacks", link: "/feedback" },
  {
    id: 15,
    name: "System Prompt for categories",
    link: "/SystemPromptCategories",
  },
  {
    id: 16,
    name: "System Prompt for AI characters",
    link: "/systempromptalcharacters",
  },
  { id: 17, name: "Chat Providers", link: "/chatProvider" },
  { id: 18, name: "Models", link: "/models" },
  { id: 19, name: "Credits To User", link: "/creditstouser" },
  { id: 20, name: "Test Account setup", link: "/testAccountSetup" },
];
