import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ISystemPrompt } from "../../api/interfaces/systemPrompt";
import { ToastContainer } from "react-toastify";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import { getAllPerkByUser } from "../../services/perk.services";
import { getUsers, getUsersItems } from "../../services/user.services";

const ViewAllUserPacks = () => {
  const [page, setPage] = useState(1);
  const [users, setUsers] = useState<any>([]);
  const [total, setTotal] = useState<any>("");
  const [selectedUser, setSelectedUser] = useState<any[] | null>(null);
  const [getAllPerk, setAllPerk] = useState<null | any[]>(null);

  useEffect(() => {
    getUsers().then((res: any) => {
      setUsers(res?.data.data);
    });
  }, []);
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  const selectedUserHandler = (val: any) => {
    getUsersItems(page, val).then((users: any) => {
      setSelectedUser(users?.data?.data ?? []);
    });
  };
  return (
    <div>
      <ToastContainer />
      <div className=" max-w-md m-auto">
        <AutoCompleteCommon
          listData={
            users.length > 0
              ? users?.map((user: any) => ({
                  value: user?.id,
                  name: user?.email,
                }))
              : []
          }
          value={selectedUser}
          placeholder="Select User"
          selectedDropDownVal={selectedUserHandler}
        />
      </div>

      {!selectedUser && <div className=" text-center py-10">Search User</div>}
      <React.Fragment>
        {selectedUser &&
          selectedUser.length > 0 &&
          selectedUser?.map((item) => (
            <div className="section-box  my-5">
              <div className="flex justify-end items-center space-x-2">
                <Link to="#">
                  <Icon icon="bx:edit" />
                </Link>
                <Link to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </div>
              {/* {categories?.length > 0 &&
          categories.map((category) => (
            <h3 key={category._id} className="mb-4">
              {category._id === item?.category && category.name}
              </h3>
            ))} */}
              <div className="all-prompts-detail">
                <div className="flex items-start mb-4">
                  <h5>Title:</h5>
                  <p>{item?.title ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Credits:</h5>
                  <p className="prompts-description">
                    {item?.credits ?? "Not Specified"}
                  </p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Credits Initially:</h5>
                  <p>{item?.creditsInitially ?? "Not Specified"}</p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Model:</h5>
                  <p className="prompts-description">
                    {item?.model ?? "Not Specified"}
                  </p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Prompt Size:</h5>
                  <p className="prompts-description">
                    {item?.promptSize ?? "Not Specified"}
                  </p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Provider:</h5>
                  <p className="prompts-description">
                    {item?.provider ?? "Not Specified"}
                  </p>
                </div>
                <div className="flex items-start mb-4">
                  <h5>Rank:</h5>
                  <p>{item?.rank ?? "Not Specified"}</p>
                </div>

                <div className="flex items-start mb-4">
                  <h5>Expires At:</h5>
                  <p>
                    {item?.expiresAt
                      ? new Date(item?.expiresAt).toLocaleString()
                      : "Not Specified"}
                  </p>
                </div>
              </div>
              <div className=" text-end w-full">
                <p>{new Date(item?.updatedAt).toLocaleString()}</p>
              </div>
            </div>
          ))}
        {selectedUser && selectedUser.length === 0 && (
          <div className=" text-center py-10">
            No User Pack Available for this User
          </div>
        )}
      </React.Fragment>
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
    </div>
  );
};

export default ViewAllUserPacks;
