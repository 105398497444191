import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getFeedbackNotAnswerByLimit } from "../../services/feedback.services";
import IndividualFeedback from "../IndividualFeedback/IndividualFeedback";

interface IProps {
  role: string;
  type: string;
  setViewAll: (id: boolean) => void;
  setActiveTab: (id: string) => void;
  setEditId: (id: string) => void;
  editId: string;
}

const NewFeedback: React.FC<IProps> = ({
  role,
  type,
  setViewAll,
  setActiveTab,
  setEditId,
  editId,
}) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const userId = "";
  const [allFeedback, setAllFeedback] = useState<any>([]);
  const [individualFeedback, setIndividualFeedback] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<any>("");
  function getNotAnswer() {
    getFeedbackNotAnswerByLimit(page, role, type, userId)
      .then((res: any) => {
        setAllFeedback(res?.data.data);
        setTotal(res?.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getNotAnswer();
    return () => {
      setAllFeedback([]);
      setTotal("");
    };
  }, [page, role, type, userId]);
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);

    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };

  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength?.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  const handleFeedback = (feed: any) => {
    setEditId(feed?.id);
    setIndividualFeedback(true);
    setFeedback(feed);
  };
  if (individualFeedback) {
    return (
      <IndividualFeedback
        editId={editId}
        setEditId={setEditId}
        role={role}
        type={type}
        feedback={feedback}
        setFeedback={setFeedback}
        setViewAll={setViewAll}
        setActiveTab={setActiveTab}
        setIndividual={setIndividualFeedback}
      />
    );
  }
  return (
    <>
      <div className="custom-search mb-5">
        <input type="text" placeholder="Search" />
        <Link to="/">
          <img alt="image-search-field" src="./assets/image/search.svg" />
        </Link>
      </div>
      <div className="sortBY">
        <p>Sort by: </p>
        <div className="space-x-2">
          <input
            className="form-check-input"
            type="radio"
            name="sortby"
            id="A-Z"
          />
          <label htmlFor="A-Z">A-Z</label>
        </div>
        <div className="space-x-2">
          <input
            className="form-check-input"
            type="radio"
            name="sortby"
            id="Z-A"
          />
          <label htmlFor="Z-A">Z-A</label>
        </div>
      </div>
      {allFeedback.map((feed: any, index: number) => (
        <div
          className="feedback-blog position-relative"
          key={index}
          onClick={() => handleFeedback(feed)}
        >
          <h5 className="pb-1">{feed?.user.username}</h5>
          <p className="feedback-time pb-2">
            Feedback received on:
            <span className="ps-2">
              {feed?.createdAt && feed?.createdAt.slice(0, 10)}
            </span>
          </p>
          <p className="feedback-detail mx-auto truncate">{feed?.feedback}</p>
          <div className="feedback-arrow">
            <svg
              width="12"
              height="15"
              viewBox="0 0 12 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.27027 15L0 13.25L7.45946 7.5L0 1.75L2.27027 0L12 7.5L2.27027 15Z"
                fill="#2E2D32"
              />
            </svg>
          </div>
        </div>
      ))}
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
    </>
  );
};

export default NewFeedback;
