import { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ICategories } from "../../api/interfaces/categories";
import {
  addCategory,
  updateIndividualCategory,
} from "../../services/categories.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import * as Yup from "yup";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import { getTags } from "../../services/tags.services";
import { Link } from "react-router-dom";

interface Props {
  isNew: boolean;
  editCategory: any;
}

const AddCategory: React.FC<Props> = (props) => {
  const [editId, setEditId] = useState<string | null>();
  const [selectedTags, setSelectedTags] = useState<any>([]);
  const [tags, setTags] = useState<string[]>([]);
  const [imagePreview, setImagePreview] = useState("");
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(""),
        description: Yup.string().required(""),
        tags: Yup.string().required(""),
        long_description: Yup.string().required(""),
        image: Yup.string().required(""),
      })
    ),
    defaultValues: {
      _id: "0",
      name: "",
      tags: "",
      description: "",
      long_description: "",
      image: "",
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    trigger,
    formState: { errors },
    getValues,
    watch,
  } = methods;

  useEffect(() => {
    getTags()
      .then((res: any) => {
        setTags(
          res?.data.data?.map((item: any) => {
            return { name: item, value: item };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
    if (props.isNew) {
      setEditId(null);
      setSelectedTags([]);
      reset();
    }
    if (props.editCategory) {
      setValue("_id", props?.editCategory._id);
      setValue("name", props?.editCategory.name);
      setValue("description", props?.editCategory.description);
      setValue("long_description", props?.editCategory?.long_description);
      setValue("image", props?.editCategory?.image);
      setValue("tags", props?.editCategory?.tags[0] ?? "");
      setSelectedTags(props?.editCategory?.tags);
      setEditId(props?.editCategory?._id);
    }
  }, [props?.editCategory, props.isNew]);

  useEffect(() => {
    setImagePreview(getValues("image"));
  }, [watch("image")]);

  const handleAdd = (formData: ICategories) => {
    if (!editId) {
      addCategory({
        name: formData?.name,
        description: formData?.description,
        image: formData?.image,
        long_description: formData.long_description,
        tags: selectedTags.filter((item: string) => item !== "") ?? [],
      }).then(() => {
        reset();
        setSelectedTags([]);
        toast.success("Added Category");
      });
    } else {
      if (formData._id) {
        updateIndividualCategory(formData._id, {
          name: formData.name,
          description: formData.description,
          image: formData.image,
          long_description: formData.long_description,
          tags: selectedTags.filter((item: string) => item !== "") ?? [],
        }).then(() => {
          toast.success("Updated Category");
          setSelectedTags([]);
          reset();
          setEditId(null);
        });
      }
    }
  };
  const tagHandler = (value: any) => {
    const findTag = selectedTags.find((tag: any) => tag === value);
    if (findTag === undefined) {
      setValue("tags", value);
      value && trigger("tags");
      setSelectedTags((item: any) => [...item, value]);
    }
  };
  const onDelete = (index: string) => {
    const filterTag = selectedTags.filter((tag: any) => tag !== index);
    setSelectedTags(filterTag);
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6">
          <FormTextField name="name" label="Title" />
          <FormTextAreaField
            className="h-[70px]"
            name="description"
            label="Short Description:"
          />
          <ul className="link-question-list">
            {selectedTags.length > 0 &&
              selectedTags.map(
                (tag: any) =>
                  tag && (
                    <li key={tag}>
                      {tag}
                      <Link onClick={() => onDelete(tag)} to="#">
                        <Icon icon="carbon:close-outline" />
                      </Link>
                    </li>
                  )
              )}
          </ul>
          <AutoCompleteCommon
            error={errors?.tags}
            label="Tags:"
            listData={tags}
            value={getValues("tags")}
            // defaultValue={{ value: getValues("tags") }}
            placeholder="Select"
            selectedDropDownVal={(val: any) => tagHandler(val)}
          />
          <FormTextAreaField
            className="h-[130px]"
            name="long_description"
            label="Long Description:"
          />
          <div className="flex space-x-3">
            <div className=" w-full self-center	">
              <FormTextField
                name="image"
                label="Image URL:"
                placeholder="Paste the image URL here"
              />
            </div>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ maxWidth: "100px", marginTop: "10px" }}
              />
            )}
          </div>
          <button className="btn-black mt-5" type="submit">
            {editId ? "Update" : "Add"}
          </button>
        </div>
        <ToastContainer />
      </FormProvider>
    </>
  );
};

export default AddCategory;
