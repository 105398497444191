import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import {
  deleteBoardRoom,
  getBoardRoomByLimit,
} from "../../services/boardroom.services";
import { ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../common/CommonAccordian";

interface SuggestedBoardroom {
  name: string;
  members: Member[];
  description: string;
}

interface Member {
  name: string;
  roles: string[];
}

interface Props {
  setActiveTab: (tab: string) => void;
  setEditId: (id: string) => void;
}

/**
 * The Viewsuggestedboardrooms component displays a list of suggested boardrooms.
 * The user can edit or delete each boardroom.
 */
const Viewsuggestedboardrooms: React.FC<Props> = ({
  setActiveTab,
  setEditId,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string | null>(null);
  const [boardRooms, setBoardRooms] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const onBoardRoomRemove = () => {
    if (removeIndex !== null) {
      deleteBoardRoom(removeIndex)
        .then(() => {
          getBoardRooms();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const getBoardRooms = () => {
    getBoardRoomByLimit(page).then((res: any) => {
      setBoardRooms(res.data.data);
      setTotal(res?.data.total);
    });
  };
  const onDelete = (index: string) => {
    setRemoveIndex(index);
    handleShow();
  };

  useEffect(() => {
    getBoardRoomByLimit(page, debouncedValue).then((res: any) => {
      setBoardRooms(res.data.data);
      setTotal(res?.data.total);
      setEditId("");
    });
  }, [page, debouncedValue]);

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);

    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };

  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  const onEdit = (index: any) => {
    setEditId(index);
    setActiveTab("first");
  };

  return (
    <>
      <ToastContainer />
      <div className=" max-w-[828px] py-[30px] m-auto">
        <div className="custom-search relative">
          <input
            type="text"
            placeholder="Search"
            onChange={handleInputChange}
            value={inputValue}
          />

          <img
            src="./assets/image/search.svg"
            alt="img-1"
            className=" absolute top-0  bottom-0 right-5 z-0  translate-y-1/2"
          />
        </div>
        <Accordion type="single" collapsible className="w-full py-4">
          {boardRooms.length > 0 &&
            boardRooms.map((boardroom: any, index: any) => (
              <AccordionItem key={index} value={boardroom._id} className="py-2">
                <AccordionTrigger className="accordian-box">
                  <div className="flex items-center space-x-4">
                    Boardroom Name : <span>{boardroom.boardroomName}</span>{" "}
                    <div className="flex space-x-2">
                      <Link to="#" onClick={() => onEdit(boardroom._id)}>
                        <Icon icon="bx:edit" />
                      </Link>
                      <Link onClick={() => onDelete(boardroom._id)} to="#">
                        <Icon icon="material-symbols:delete-outline" />
                      </Link>
                    </div>
                  </div>
                </AccordionTrigger>
                <AccordionContent className=" py-3">
                  {boardroom.boardroomCharacters.map(
                    (member: any, memberIndex: any) => (
                      <div className="Boardroom-box" key={memberIndex}>
                        <div className="Boardroom-button">
                          {/* <Link to="#" onClick={() => onEdit(boardroom._id)}>
                            <Icon icon="bx:edit" />
                          </Link> */}
                          {/* <Link onClick={() => onDelete(boardroom._id)} to="#">
                            <Icon icon="material-symbols:delete-outline" />
                          </Link> */}
                        </div>
                        <div className="Boardroom-Media">
                          <span>
                            <img
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "contain",
                              }}
                              src={member.logo}
                              alt="member_logo"
                            />
                          </span>
                          <div>
                            <h6>{member.name}</h6>
                            <ol>
                              {member.tags.map((role: any, roleIndex: any) => (
                                <li key={roleIndex}>{role}</li>
                              ))}
                            </ol>
                          </div>
                        </div>
                        <p>{boardroom.description}</p>
                      </div>
                    )
                  )}
                </AccordionContent>
              </AccordionItem>
            ))}
        </Accordion>
        <div className="pagination">
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
            disabled={page === 1}
            onClick={() => previous()}
          >
            {"<"}
          </button>
          <>{PagePagination()}</>
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
            disabled={page === TotalLength}
            onClick={() => next()}
          >
            {">"}
          </button>
        </div>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onBoardRoomRemove}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Viewsuggestedboardrooms;
