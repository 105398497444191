import React from "react";
import { toast, ToastContainer } from "react-toastify";
import { updateIndividualFeedback } from "../../services/feedback.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";

interface IProps {
  editId: string;
  setEditId: (id: string) => void;
  feedback: any;
  setFeedback: (id: string) => void;
  role: string;
  type: string;
  setViewAll: (id: boolean) => void;
  setActiveTab: (id: string) => void;
  setIndividual: (id: boolean) => void;
}

const IndividualFeedback: React.FC<IProps> = ({
  editId,
  setEditId,
  feedback,
  setFeedback,
  role,
  type,
  setViewAll,
  setIndividual,
  setActiveTab,
}) => {
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        answer: Yup.string().required(""),
      })
    ),
    defaultValues: {
      feedback: feedback?.feedback ?? "",
      answer: "",
    },
  });
  const { handleSubmit, reset } = methods;
  const handleAdd = (formData: any) => {
    updateIndividualFeedback(editId, { answer: formData?.answer })
      .then((res: any) => {
        toast.success("Answer Submit Successful!!");
        setEditId("");
        setFeedback("");
        reset();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = (id: string) => {
    setIndividual(false);
    setEditId(id);
    setViewAll(true);
    setActiveTab("second");
  };
  return (
    <>
      <ToastContainer />
      <div
        style={{
          borderRadius: "10px",
          border: "0.25px solid #fff",
          background: "#FFF",
          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
          padding: "40px 25px",
          position: "relative",
        }}
      >
        <span className="flex justify-end">
          {feedback ? feedback?.createdAt.slice(0, 10) : ""}
        </span>
        <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
          <div className="max-50 space-y-4">
            <FormTextAreaField
              name="feedback"
              label={feedback?.user?.username ?? "" + ":"}
              placeholder="Enter the title"
              className="h-[140px] "
              readOnly
            />
            <FormTextAreaField
              name="answer"
              label="Team Response:"
              placeholder="Enter your Response here..."
              className="h-[180px] "
            />
          </div>
          <button className="btn-black mt-5" type="submit">
            Send Response
          </button>
        </FormProvider>
      </div>
      <div className="text-end mt-5">
        <h5 className="underline">
          <u
            style={{ cursor: "pointer" }}
            onClick={() => handleClick(feedback?.user.id)}
          >
            View All Feedback from {feedback?.user?.username ?? ""}
          </u>
        </h5>
      </div>
    </>
  );
};

export default IndividualFeedback;
