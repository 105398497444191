import { Icon } from "@iconify/react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deletePerkById } from "../../services/perk.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Perk {
  id: string | undefined | number;
  title: string;
  description: string;
  value: number | string;
}

interface Props {
  handleTabChange: (tab: string) => void;
  allPerk: Perk[];
  getAllPerks: () => void;
  setEditId: (id: string) => void;
}

/**
 * The ViewPerkType component displays a list of perks.
 * The user can edit or delete each perk.
 */
const ViewPerkType: React.FC<Props> = ({
  handleTabChange,
  allPerk,
  getAllPerks,
  setEditId,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string | undefined | number>(
    ""
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onEditPerk = (perk: Perk) => {
    localStorage.setItem("editPerk", JSON.stringify(perk));
    setEditId(perk?.id as string);
    handleTabChange("first");
  };

  const onRemoveQuestion = () => {
    if (removeIndex !== "") {
      deletePerkById(removeIndex as any)
        .then(() => {
          getAllPerks();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onDelete = (index: string | undefined | number) => {
    setRemoveIndex(index);
    handleShow();
  };

  return (
    <>
      <div className="max-50 view-content tags-main">
        {allPerk?.length > 0 &&
          allPerk?.map((perk, index) => (
            <div className="perkBox" key={index}>
              <span>
                <Link to="#" onClick={() => onEditPerk(perk)}>
                  <Icon icon="bx:edit" />
                </Link>
                <Link onClick={() => onDelete(perk.id)} to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </span>
              <h5>{perk.title}</h5>
              <h6>
                <strong>Description:</strong> <i>{perk.description}</i>
              </h6>
              <h6>
                <strong>Credits:</strong> <i>{perk.value}</i>
              </h6>
            </div>
          ))}
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewPerkType;
