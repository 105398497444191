import http from "../http/request";

function addSystemPrompt(body: any) {
  return new Promise((resolve, reject) => {
    http(`/gpt-prompts/pools/pool`, "post", body, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getSystemPrompt() {
  return new Promise((resolve, reject) => {
    http(`/gpt-prompts/pools/pool`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getSystemPromptByLimit(page: number, id: string | null, type: string, provider: string, model: string) {
  return new Promise((resolve, reject) => {
    http(
      `/gpt-prompts/pools/pool?page=${page}&limit=5${id
        ? type === "category"
          ? `&categoryId=${id}`
          : `&characterId=${id}`
        : ""
      }&provider=${provider}&model=${model}`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deleteSystemPrompt(id: string) {
  return new Promise((resolve, reject) => {
    http(`/gpt-prompts/pools/pool/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function individualSystemPrompt(id: string) {
  return new Promise((resolve, reject) => {
    http(`/gpt-prompts/pools/pool/${id}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateSystemPrompt(id: string | null, body: any) {
  return new Promise((resolve, reject) => {
    http(`/gpt-prompts/pools/pool/${id}`, "put", body, true)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export {
  addSystemPrompt,
  getSystemPrompt,
  deleteSystemPrompt,
  individualSystemPrompt,
  updateSystemPrompt,
  getSystemPromptByLimit,
};
