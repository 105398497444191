import { useState, useEffect, useMemo } from "react";
import * as React from "react";
import Addmodel from "../../component/Addmodel/Addmodel";
import Viewmodel from "../../component/Viewmodel/Viewmodel";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";

interface Model {
  id: number;
  name: string;
  model: string;
}

const Models: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [allmodel, setAllmodel] = useState<Model[]>([]);

  const fetchDataFromLocalStorage = () => {
    const storedData = localStorage.getItem("getmodalData");
    if (storedData) {
      setAllmodel(JSON.parse(storedData));
    }
  };

  // Event handler for tab change
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    fetchDataFromLocalStorage(); // Fetch data when tab changes
  };

  // Initial data fetch when component mounts
  useEffect(() => {
    fetchDataFromLocalStorage();
  }, []);

  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="first" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a model</TabsTrigger>
          <TabsTrigger value="second">View all models</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <Addmodel />{" "}
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <Viewmodel />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Models;
