import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ItemsData } from "./HomeData";

/**
 * Home component.
 * Renders a list of links to different pages.
 */
const Home: React.FC = () => {
  return (
    <div className="Homepagecontent">
      <h1>Click on the item you want to add</h1>
      <ul>
        {ItemsData?.map((item) => (
          <li key={item.id}>
            <Link to={item.link}>
              <p>{item?.name}</p>
              <i>
                <Icon icon="mingcute:right-line" />
              </i>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home;
