import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { ISystemPrompt } from "../../api/interfaces/systemPrompt";
import {
  deleteSystemPrompt,
  getSystemPromptByLimit,
} from "../../services/systemPrompt";
import { toast, ToastContainer } from "react-toastify";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import { getProviderModel } from "../../services/provider-model.services";

interface IProps {
  characters: IAICharacters[];
  setEditId: (id: string) => void;
  setActiveTab: (id: string) => void;
}
interface ProviderModel {
  id?: string;
  model: string;
  provider: string;
}

const ViewSystemPromptCharacters: React.FC<IProps> = ({
  characters,
  setEditId,
  setActiveTab,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string | null>(null);
  const [systemPrompt, setSystemPrompt] = useState<ISystemPrompt[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const [selectedDropdown, setSelectedDropdown] = useState<string | null>(null);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [modelVal, setModelVal] = useState("");
  const [selectedProvider, setSelectedProvider] = useState("");

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  useEffect(() => {
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);

  const onRemoveQuestion = () => {
        if (removeIndex !== null) {
      deleteSystemPrompt(removeIndex)
        .then(() => {
          getSystemPromptByLimit(
            page,
            null,
            "character",
            selectedProvider,
            modelVal
          );
          toast.success("Pool Deleted Successfully!!");
          handleClose();
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          console.log(err);
        });
    }
  };

  const onDelete = (id: any) => {
      setRemoveIndex(id);
      handleShow();
  };
  const onEdit = (index: any) => {
    setEditId(index);
    setActiveTab("first");
  };
  // function getSystemPromp() {
  //   getSystemPromptByLimit(page).then((res: any) => {
  //     setSystemPrompt(res.data.data);
  //     setTotal(res?.data.total);
  //   });
  // }

  useEffect(() => {
    if (selectedDropdown || modelVal || selectedProvider) {
      getSystemPromptByLimit(
        page,
        selectedDropdown,
        "character",
        selectedProvider,
        modelVal
      ).then((res: any) => {
        setSystemPrompt(res.data.data);
        setTotal(res?.data.total);
      });
    }
  }, [page, selectedDropdown, selectedProvider, modelVal]);

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  return (
    <div>
      <ToastContainer />
      <div className=" py-4 m-auto flex justify-evenly items-center space-x-2 custom-form">
        <AutoCompleteCommon
          listData={characters?.map((item) => ({
            value: item._id,
            name: item.name,
          }))}
          value={selectedDropdown}
          placeholder="Select Character"
          selectedDropDownVal={(val: any) => {
            setSelectedDropdown(val);
          }}
        />
        <select
          value={selectedProvider}
          // disabled={selectedDropdown === null}
          className="border-1 border-l-[#f6f4f5] border-t-[#f6f4f5] form-select px-3 w-full max-w-[230px]"
          aria-label="Default select example"
          name="provider"
          onChange={(e: any) => setSelectedProvider(e.target.value)}
        >
          <option selected value="" className=" hidden">
            Choose Provider
          </option>
          {providers?.map((item) => (
            <option value={item.provider}>{item.provider}</option>
          ))}
        </select>
        <AutoCompleteCommon
          disabled={selectedProvider === ""}
          listData={providers.flatMap((item: any) =>
            item?.provider === selectedProvider
              ? item?.models?.map((subModel: any) => ({
                  value: subModel,
                  name: subModel,
                }))
              : []
          )}
          value={modelVal}
          placeholder="Select Model"
          selectedDropDownVal={(val: any) => {
            setModelVal(val);
          }}
        />
      </div>
      {!selectedDropdown && !selectedProvider && (
        <div className=" py-6">
          <h3 className="mb-4 text-center text-xl">Select Option</h3>
        </div>
      )}
      {systemPrompt?.length > 0 &&
        systemPrompt?.map((item: ISystemPrompt) => (
          <React.Fragment key={item?._id}>
            {item.type === "character" && (
              <div className="section-box promptcategories-box">
                <div className="flex justify-end items-center mb-2 space-x-2">
                  <Link to="#" onClick={() => onEdit(item?._id)}>
                    <Icon icon="bx:edit" />
                  </Link>
                  <Link onClick={() => onDelete(item?._id)} to="#">
                    <Icon icon="material-symbols:delete-outline" />
                  </Link>
                </div>
                {characters?.length > 0 &&
                  characters.map((character) => (
                    <h3 key={character._id} className="mb-4">
                      {character._id === item.character && character.name}
                    </h3>
                  ))}
                <div className="all-prompts-detail">
                  <div className="flex items-start mb-4">
                    <h5>Provider:</h5>
                    <p className="prompts-description">
                      {item.provider ?? "Not Specified"}
                    </p>
                  </div>
                  <div className="flex items-start mb-4">
                    <h5>Model:</h5>
                    <p className="prompts-description">
                      {item.model ?? "Not Specified"}
                    </p>
                  </div>
                  <div className="flex items-start mb-4">
                    <h5>Description:</h5>
                    <p className="prompts-description">{item.systemPrompt}</p>
                  </div>
                  <div className="flex items-start mb-4">
                    <h5>Version No.:</h5>
                    <p>{item.version}</p>
                  </div>
                  <div className="flex items-start mb-4">
                    <h5>isActive:</h5>
                    <p>{item.isActive ? "True" : "False"}</p>
                  </div>
                </div>
                  <div className=" text-end w-full">
                    <p>{new Date( item?.updatedAt).toLocaleString()}</p>
                  </div>
              </div>
            )}
          </React.Fragment>
        ))}
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewSystemPromptCharacters;
