import { useEffect, useMemo, useState } from "react";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import AddSystemPromptCharacters from "../../component/AddSystemPromptCharacters/AddSystemPromptCharacters";
import ViewSystemPromptCharacters from "../../component/ViewSystemPromptCharacters/ViewSystemPromptCharacters";
import { getAllCharacter } from "../../services/character.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * Component for managing system prompt categories.
 * @component
 * @returns {JSX.Element} SystemPromptCategories component
 */
const SystemPromptCharacter = () => {
  const [activeTab, setActiveTab] = useState("first");
  const [characters, setCharacters] = useState<IAICharacters[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const handleTab = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (activeTab === "first") {
      getAllCharacter().then((res: any) => {
        setCharacters(res?.data.data);
      });
    }
  }, [activeTab]);

  return (
    <div className="Homepagecontent">
      <Tabs
        onValueChange={(val) => {
          setActiveTab(val);
          setEditId("");
        }}
        value={activeTab}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add system prompt</TabsTrigger>
          <TabsTrigger value="second">View system prompts</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <AddSystemPromptCharacters editId={editId} setEditId={setEditId} />
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <ViewSystemPromptCharacters
            characters={characters}
            setEditId={setEditId}
            setActiveTab={setActiveTab}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SystemPromptCharacter;
