import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import {
  deleteProvider,
  getProviderModel,
} from "../../services/provider-model.services";
import { toast } from "react-toastify";

interface ChatProvider {
  id: string;
  provider: string;
  model: string;
}

/**
 * The ViewChatProvider component displays a list of chat providers.
 * The user can edit or delete each chat provider and navigate through pagination.
 */
const ViewChatProvider: React.FC = () => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string>("");
  const [allChatProviders, setAllChatProviders] = useState<ChatProvider[]>([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onRemoveQuestion = () => {
    if (removeIndex !== "") {
      deleteProvider(removeIndex)
        .then(() => {
          toast.success("Provider Deleted Successfully!!");
          getAllProviderModel();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onDelete = (index: string) => {
    setRemoveIndex(index);
    handleShow();
  };
  function getAllProviderModel() {
    getProviderModel()
      .then((res: any) => {
        setAllChatProviders(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getAllProviderModel();
  }, []);
  return (
    <>
      <div className="max-50 view-content">
        {allChatProviders?.length > 0 &&
          allChatProviders?.map((provider: ChatProvider, index: number) => (
            <div className="custom-card modal-card" key={index}>
              {/* <div className="cardMediaAction">
                <Link onClick={() => onDelete(provider?.id)} to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </div> */}
              <div className="modal-info">
                <h6>
                  <i>Provider Name:</i>
                  <span>{provider?.provider}</span>
                </h6>
              </div>
            </div>
          ))}
      </div>
      {/* <Modal className="delete-modal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="btn-black-border" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="" className="btn-black" onClick={onRemoveQuestion}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default ViewChatProvider;
