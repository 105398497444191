import { useMemo, useState } from "react";
import AddCategoryQuestion from "../../component/AddCategoryQuestion/AddCategoryQuestion";
import ViewAllQuestion from "../../component/ViewAllQuestion/ViewAllQuestion";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * The QuestionCategories component displays a tabbed interface for adding and viewing questions.
 * The user can switch between tabs to add a question or view all questions.
 */
const QuestionCategories: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [editId, setEditId] = useState<string>("");

  return (
    <div className="Homepagecontent">
      <Tabs
        value={activeTab}
        onValueChange={(val) => {
          setEditId("");
          setActiveTab(val);
        }}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a Question</TabsTrigger>
          <TabsTrigger value="second">View all Questions</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <AddCategoryQuestion editId={editId} setEditId={setEditId} />
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <ViewAllQuestion
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setEditId={setEditId}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default QuestionCategories;
