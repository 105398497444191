import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createTags, updateTags } from "../../services/tags.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
interface IProps {
  setEditId: (id: string) => void;
  editId: string;
}

/**
 * The Addtag component represents a form for adding a tag.
 * It allows the user to enter the name of the tag.
 * The user can add a new tag or update an existing tag.
 * The recently added tags are displayed in a list.
 * Each tag in the list can be edited or deleted.
 */
const Addtag: React.FC<IProps> = ({ setEditId, editId }) => {
  const [show, setShow] = useState(false);
  // const [tag, setTag] = useState<string>("");
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        tag: Yup.string().required(""),
      })
    ),
    defaultValues: {
      tag: "",
    },
  });
  const { handleSubmit, reset, setValue, getValues } = methods;

  const handleAdd = ({ tag }: any) => {
    if (editId) {
      updateTags({ newTag: tag, oldTag: editId })
        .then(() => {
          reset();
          setEditId("");
          toast.success("Tag Updated Successfully!!!");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    } else {
      createTags({ tag: tag })
        .then(() => {
          reset();
          toast.success("Tag Added");
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };
  useEffect(() => {
    if (editId) {
      setValue("tag", editId);
    }
  }, [editId]);
  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6">
          <FormTextField name="tag" label="Name of the tag:" />{" "}
          <button type="submit" className="btn-black mt-5">
            {editId ? "Update" : "Add"}
          </button>
        </div>
      </FormProvider>
    </>
  );
};

export default Addtag;
