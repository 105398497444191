import { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { ItemsData } from "../../pages/Home/HomeData";
import {
  GoToLogin,
  getUserData,
  isAuthenticated,
  kcLogout,
} from "../../RequireAuth";

/**
 * The Header component represents the header of the website.
 * It includes a logo and a navigation link to the home page.
 */
const Header: React.FC = () => {
  const { pathname } = useLocation();
  const [userData, setUserData] = useState<any>({});
  const [selectedPathName, setSelectedPathName] = useState<String | null>(null);
  const updateUserData = () => {
    getUserData()
      .then((res) => setUserData(res))
      .catch((err) => {});
  };
  useEffect(() => {
    const selectedPath = ItemsData.find((item) => item?.link === pathname);
    selectedPath
      ? setSelectedPathName(selectedPath?.name)
      : setSelectedPathName(null);
  }, [pathname]);

  useEffect(() => updateUserData(), []);

  const logout = () => {
    kcLogout().then((res) => {
      setUserData({});
      window.location.reload();
    });
  };
  const login = () => {
    GoToLogin();
  };

  return (
    <header className="header-flex">
      <Link className="nav-logo" to="/">
        <img alt="logo-img" src="./assets/image/logo.png" />
      </Link>
      <NavLink
        onClick={updateUserData}
        className={({ isActive }) =>
          "header-link" + (isActive ? " active" : "")
        }
        to="/"
        end
      >
        Home
      </NavLink>
      {selectedPathName && (
        <div className="active header-link">{selectedPathName}</div>
      )}
      <div className="header-link">
        <span>{userData.email}</span>
      </div>
      <div className="ml-auto">
        {isAuthenticated() ? (
          <div className="header-link cursor-pointer" onClick={logout}>
            logout
          </div>
        ) : (
          <div className="header-link cursor-pointer" onClick={login}>
            login
          </div>
        )}
      </div>
    </header>
  );
};

export default Header;
