import { useEffect, useMemo, useState } from "react";
import AddCharacterQuestion from "../../component/AddCharacterQuestion/AddCharacterQuestion";
import ViewCharacterQuestion from "../../component/ViewCharacterQuestion/ViewCharacterQuestion";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * The QuestionCharacter component displays a tabbed interface for adding and viewing character questions.
 * The user can switch between tabs to add a question or view all questions.
 */
const QuestionCharacter: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [editId, setEditId] = useState<string>("");

  return (
    <div className="Homepagecontent">
      <Tabs
        value={activeTab}
        onValueChange={(val) => {
          setActiveTab(val);
          setEditId("");
        }}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a Question</TabsTrigger>
          <TabsTrigger value="second">View all Questions</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <AddCharacterQuestion editId={editId} setEditId={setEditId} />{" "}
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <ViewCharacterQuestion
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            setEditId={setEditId}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default QuestionCharacter;
