import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { deleteProvider, deleteProviderModel, getProviderModel } from "../../services/provider-model.services";

interface Model {
  id: string
  models: string[];
  provider: string
}

/**
 * The Viewmodel component displays a list of models.
 * The user can edit or delete each model and navigate through pagination.
 */
const Viewmodel: React.FC = () => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string>("");
  const [model, setModel] = useState<string>("");
  const [allmodel, setAllModel] = useState<Model[]>([])

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onRemoveQuestion = () => {
    if (removeIndex !== "") {
      deleteProviderModel(removeIndex, model).then(() => {
        toast.success("Model Deleted Successfully!!")
        getAllProviderModel()
        handleClose();
      }).catch((err) => {
        console.log(err)
      })
    }
  };

  const onDelete = (index: string, modelName: string) => {
    setRemoveIndex(index);
    setModel(modelName)
    handleShow();
  };
  function getAllProviderModel() {
    getProviderModel().then((res: any) => {
      setAllModel(res?.data.data)
    }).catch((err) => {
      console.log(err)
    })
  }
  useEffect(() => {
    getAllProviderModel()
  }, [])

  return (
    <>
      <ToastContainer />
      <div className="max-50 view-content" >
        {allmodel?.map((model, index) => (
          <div className="custom-card modal-card py-5" key={index}>
            <div className="modal-info">
              <h6>
                <i>Provider Name:</i>
                <span>{model?.provider}</span>
              </h6>
              <h6>
                <i>Model No:</i>
                {
                  model?.models?.length > 0 && model?.models?.map((modelName) => modelName !== " " && (
                    <div className="flex px-2 gap-1">
                      <span>{modelName}</span>
                      {/* <Link onClick={() => onDelete(model?.id, modelName)} to="#">
                        <Icon icon="material-symbols:delete-outline" />
                      </Link> */}
                    </div>
                  ))
                }
              </h6>
            </div>
          </div>
        ))}
      </div>
      {/* <Modal className="delete-modal" show={show} onHide={handleClose} centered>
        <Modal.Body>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="" className="btn-black-border" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="" className="btn-black" onClick={onRemoveQuestion}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal> */}
    </>
  );
};

export default Viewmodel;
