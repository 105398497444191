import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addProviderModel } from "../../services/provider-model.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormSelectField from "../../common/hook-form/FormSelectField";

interface ChatProvider {
  provider: string;
  model: string;
}

const AddChatProvider: React.FC = () => {
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        provider: Yup.string().required(""),
      })
    ),
    defaultValues: {
      model: "",
      provider: "",
    },
  });

  const { handleSubmit, reset } = methods;

  const handleAdd = (formData: ChatProvider) => {
    addProviderModel({ ...formData })
      .then(() => {
        toast.success("Provider Added Successful!!");
        reset();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6 ">
          <FormTextField
            name="provider"
            label="Provider Name:"
            placeholder="Provider Name"
          />
          <FormTextField
            name="model"
            label="Model Name:"
            placeholder="Model Name"
          />
          <button type="submit" className="btn-black mt-5">
            Add
          </button>
        </div>
      </FormProvider>
      {/* <Form className="max-50">
        <Form.Group className="custom-form mb-4 justify-content-center">
          <Form.Label>Provider Name:</Form.Label>
          <Form.Control
            type="text"
            name="provider"
            value={data.provider}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Button
          variant=""
          className="btn-black mt-5"
          onClick={() => handleAdd()}
        >
          {editId ? "Update" : "Add"}
        </Button>
      </Form> */}
    </>
  );
};

export default AddChatProvider;
