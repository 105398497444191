// import React from "react";
import "./App.scss";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./component/Header/Header";
import Categories from "./pages/Categories/Categories";
import Character from "./pages/Character/Character";
import ChatProvider from "./pages/ChatProvider/ChatProvider";
import CreditPack from "./pages/CreditPack/CreditPack";
import Feedback from "./pages/FeedBack/FeedBack";
import Home from "./pages/Home/Home";
import LinkQuestion from "./pages/LinkQuestion/LinkQuestion";
import LinkQuestionCharacter from "./pages/LinkQuestionCharacter/LinkQuestionCharacter";
import Models from "./pages/Models/Models";
import Perkpage from "./pages/Perkpage/Perkpage";
import PerkType from "./pages/PerkType/PerkType";
import QuestionCategories from "./pages/QuestionCategories/QuestionCategories";
import QuestionCharacter from "./pages/QuestionCharacter/QuestionCharacter";
import SuggestedBoardrooms from "./pages/SuggestedBoardrooms/SuggestedBoardrooms";
import SystemPromptAlcharacters from "./pages/SystemPromptAlcharacters/SystemPromptAlcharacters";
import SystemPromptCategories from "./pages/SystemPromptCategories/SystemPromptCategories";
import Tagpage from "./pages/Tagpage/Tagpage";
import CreditsToUser from "./pages/CreditsToUser/CreditsToUser";
import RequireAuth from "./RequireAuth";
import TestAccountSetup from "./pages/TestAccountSetup/TestAccountSetup";
import AddSuggestedQuestionPool from "./pages/AddSuggestedQuestionPool/AddSuggestedQuestionPool";
import LinkSuggestedQuestionPool from "./pages/LinkSuggestedQuestionPool/LinkSuggestedQuestionPool";

/* Styles */
function App(): JSX.Element {
  return (
    <BrowserRouter>
      <Header />
      <div className="d-margin">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/categories"
            element={
              <RequireAuth>
                <Categories />
              </RequireAuth>
            }
          />
          <Route
            path="/character"
            element={
              <RequireAuth>
                <Character />
              </RequireAuth>
            }
          />
          <Route
            path="/questionCategories"
            element={
              <RequireAuth>
                <QuestionCategories />
              </RequireAuth>
            }
          />
          <Route
            path="/questionCharacter"
            element={
              <RequireAuth>
                <QuestionCharacter />
              </RequireAuth>
            }
          />
          <Route
            path="/linkQuestion"
            element={
              <RequireAuth>
                <LinkQuestion />
              </RequireAuth>
            }
          />
          <Route
            path="/linkQuestionCharacter"
            element={
              <RequireAuth>
                <LinkQuestionCharacter />
              </RequireAuth>
            }
          />
          <Route
            path="/suggestedboardrooms"
            element={
              <RequireAuth>
                <SuggestedBoardrooms />
              </RequireAuth>
            }
          />
          <Route
            path="/models"
            element={
              <RequireAuth>
                <Models />
              </RequireAuth>
            }
          />
          <Route
            path="/chatProvider"
            element={
              <RequireAuth>
                <ChatProvider />
              </RequireAuth>
            }
          />
          <Route
            path="/tag"
            element={
              <RequireAuth>
                <Tagpage />
              </RequireAuth>
            }
          />
          <Route
            path="/perkType"
            element={
              <RequireAuth>
                <PerkType />
              </RequireAuth>
            }
          />
          <Route
            path="/perkPage"
            element={
              <RequireAuth>
                <Perkpage />
              </RequireAuth>
            }
          />
          <Route
            path="/CreditPack"
            element={
              <RequireAuth>
                <CreditPack />
              </RequireAuth>
            }
          />
          <Route
            path="/feedback"
            element={
              <RequireAuth>
                <Feedback />
              </RequireAuth>
            }
          />
          <Route
            path="/SystemPromptCategories"
            element={
              <RequireAuth>
                <SystemPromptCategories />
              </RequireAuth>
            }
          />
          <Route
            path="/add-suggested-question-pool"
            element={
              <RequireAuth>
                <AddSuggestedQuestionPool />
              </RequireAuth>
            }
          />
          <Route
            path="/link-suggested-question-pool"
            element={
              <RequireAuth>
                <LinkSuggestedQuestionPool />
              </RequireAuth>
            }
          />
          <Route
            path="/systempromptalcharacters"
            element={
              <RequireAuth>
                <SystemPromptAlcharacters />
              </RequireAuth>
            }
          />
          <Route
            path="/creditstouser"
            element={
              <RequireAuth>
                <CreditsToUser />
              </RequireAuth>
            }
          />
          <Route
            path="/testAccountSetup"
            element={
              <RequireAuth>
                <TestAccountSetup />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
