import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ICategories } from "../../api/interfaces/categories";
import {
  deleteIndividualCategory,
  getCategoriesBylimit,
} from "../../services/categories.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Props {
  // fetchDataFromLocalStorage: () => void;
  setEditCategoryData: (category: ICategories) => void;
  // allcategory: ICategories[];
  handleTabChange: (tab: string) => void;
}

/**
 * The ViewCategory component displays a list of categories.
 * The user can search for categories, edit or delete each category,
 * and navigate through pagination.
 */
const ViewCategory: React.FC<Props> = ({
  // fetchDataFromLocalStorage,
  handleTabChange,
  setEditCategoryData,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);
  const [allcategory, setAllCategory] = useState<ICategories[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const onEditChatProvider = (category: ICategories) => {
    setEditCategoryData(category);
    localStorage.setItem("editCategory", JSON.stringify(category));
    handleTabChange("addCategory");
  };

  const onRemoveQuestion = () => {
    const data = allcategory.slice();
    if (removeIndex !== null) {
      deleteIndividualCategory(allcategory[removeIndex!]._id)
        .then(() => {
          data.splice(removeIndex, 1);
          localStorage.setItem("addcategory", JSON.stringify(data));
          toast.success("Deleted Successfully!!");
          getCategory();
          handleClose();
          // fetchDataFromLocalStorage();
        })
        .catch((err) => {
          toast.error(err.response.data.message);
        });
    }
  };

  const onDelete = (index: number) => {
    setRemoveIndex(index);
    handleShow();
  };
  function getCategory() {
    getCategoriesBylimit(page, debouncedValue).then((res: any) => {
      setAllCategory(res.data.data);
      setTotal(res?.data.total);
    });
  }
  useEffect(() => {
    getCategoriesBylimit(page, debouncedValue).then((res: any) => {
      setAllCategory(res.data.data);
      setTotal(res?.data.total);
    });
  }, [page, debouncedValue]);
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  return (
    <>
      <ToastContainer />
      <div className="max-50 view-content">
        <h3>Added Categories</h3>
        <div className="custom-search">
          <input
            type="text"
            placeholder="Search"
            onChange={handleInputChange}
            value={inputValue}
          />
          <Link to="/">
            <img src="./assets/image/search.svg" />
          </Link>
        </div>
        {allcategory.length > 0 &&
          allcategory?.map((category, index) => (
            <div className="custom-card" key={index}>
              <div className="cardMediaAction">
                <Link to="#" onClick={() => onEditChatProvider(category)}>
                  <Icon icon="bx:edit" />
                </Link>
                <Link onClick={() => onDelete(index)} to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </div>
              <div className="cardMedia">
                <span>
                  <img src={category.image} alt="Category" />
                </span>
                <h6>{category.name}</h6>
              </div>
              <div className="flex gap-4 pb-4">
                <p style={{ whiteSpace: "nowrap", margin: "0" }}>
                  Short Description:
                </p>
                <p style={{ textAlign: "start", margin: "0" }}>
                  {category.description}
                </p>
              </div>
              <div className="flex gap-4">
                <p style={{ whiteSpace: "nowrap", margin: "0" }}>
                  Long Description:
                </p>
                <p style={{ textAlign: "start", margin: "0" }}>
                  {category.long_description}
                </p>
              </div>
            </div>
          ))}
        <div className="pagination">
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
            disabled={page === 1}
            onClick={() => previous()}
          >
            {"<"}
          </button>
          <>{PagePagination()}</>
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
            disabled={page === TotalLength}
            onClick={() => next()}
          >
            {">"}
          </button>
        </div>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewCategory;
