import { useFormContext, Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
import * as Checkbox from "@radix-ui/react-checkbox";

export default function FormCheckBoxField({
  name,
  label,
  type,
  outerDivStyle,
  className,
  ref,
  errorStyle,
  labelCss,
  ...other
}: any) {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <div className="custom-form items-center">
            {label && (
              <label
                className={`${!!error ? "text-error" : ""} ${labelCss}`}
                htmlFor={name}
              >
                {label}
              </label>
            )}
            {/* <input
              className={cn(
                `w-full ${
                  !!error
                    ? "focus:border-error border-2 border-error"
                    : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5]"
                } ${textInputCss}`
              )}
              type={type}
              {...field}
              {...other}
              onBlur={(e) => {
                trigger(name);
                onBlurHandler();
              }}
              onChange={(e) => {
                field.onChange(e);
                trigger(name);
                onChange(e);
              }}
            /> */}
            <Checkbox.Root
              className={cn(
                "peer h-[20px] w-[20px] shrink-0 rounded-[5px] border-2 border-regentgreyColor ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-black data-[state=checked]:border-transparent data-[state=checked]:text-primary-foreground",
                className
              )}
              id={name}
              onCheckedChange={field.onChange}
              checked={field.value}
              {...field}
              {...other}
            >
              <Checkbox.Indicator className="flex items-center justify-center text-current">
                <svg
                  width="17"
                  height="17"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                    fill="#fff"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </Checkbox.Indicator>
            </Checkbox.Root>
          </div>
          {/* {!!error && (
            <h3 className={"text-f12 text-error "}>{error?.message}</h3>
          )} */}
        </>
      )}
    />
  );
}
