import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import { getUsers } from "../../services/user.services";

/**
 * The ViewAllTestAccount component displays a list of perks.
 * Each perk has a name and an assigned person.
 * The user can edit or delete each perk.
 */
const ViewAllTestAccount: React.FC = () => {
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [getAllTestUser, setAllTestUser] = useState<null | any[]>(null);
  const [selectedUser, setSelectedUser] = useState("");
  const [total, setTotal] = useState<any>("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getUsers(page, "test").then((res: any) => {
      setAllTestUser(res.data.data);
      setTotal(res?.data.total);
    });
  }, [page, selectedUser]);

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };
  return (
    <div className="p-4">
      <div className="max-50 view-content tags-main space-y-5">
        {getAllTestUser &&
          getAllTestUser.map((user, index) => (
            <div className="flex  p-[15px] border-t-2 shadow-sm border-l-2 rounded-[10px]" key={index}>
              <h6 className=" text-f18 font-semibold max-w-[170px] w-full">{user?.account_type}</h6>
              <div>
                <p className="text-f18 font-normal ">
                  <strong>Name:</strong>{" "}
                  <i className="not-italic pl-3">{user?.first_name ?? "Not Specified"},</i>
                </p>
                <p>
                  <strong>Email:</strong> <i className="not-italic pl-6">{user?.email}</i>
                </p>
              </div>
              {/* <span>
                <Link to="#" onClick={() => setActiveTab("first")}>
                  <Icon icon="bx:edit" />
                </Link>
                <Link onClick={handleShow} to="#">
                  <Icon icon="material-symbols:delete-outline" />
                </Link>
              </span> */}
            </div>
          ))}
      </div>
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={handleClose}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ViewAllTestAccount;
