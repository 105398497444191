import "react-toastify/dist/ReactToastify.css";
import { Icon } from "@iconify/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { ICategories } from "../../api/interfaces/categories";
import {
  getAllCategoryQuestionsPool,
  getCategories,
} from "../../services/categories.services";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import ErrorMessage from "../../common/ErrorMessage";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import {
  createSuggestedQuestionsPool,
  getAllSuggestedQuestionsPool,
  getBoardRoomByLimit,
  updateSuggestedQuestionsPool,
} from "../../services/boardroom.services";
interface Questions {
  name: string;
  boardroomPoolId: string;
  questions: string[];
}

interface IProps {
  editId: string;
  setEditId: (id: string) => void;
}

const AddSuggestedQuestion: React.FC<IProps> = ({ editId, setEditId }) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);
  const [boardRoom, setBoardRoom] = useState<
    Partial<{ boardroomName: string; _id: string }[]>
  >([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [addedQuestion, setAddedQuestion] = useState<any[]>([]);

  // react hook form

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required(""),
        boardroomPoolId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      name: "",
      boardroomPoolId: "",
      questions: "",
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = methods;

  // react hook form

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onAddQuestion = () => {
    if (getValues("questions") !== "") {
      setAddedQuestion((prev: any) => [...prev, getValues("questions")]);
      setValue("questions", "");
      toast.success("Added category Question successfully!");
      setErrorMessage(null);
    } else {
      setErrorMessage("Question is  Required");
    }
  };

  const onRemoveQuestion = () => {
    if (removeIndex !== null) {
      const updatedQuestions = addedQuestion.filter(
        (_, index) => index !== removeIndex
      );
      setAddedQuestion(updatedQuestions);
      handleClose();
    }
  };

  const onDelete = (index: number) => {
    setRemoveIndex(index);
    handleShow();
  };
  const onQuestion = (credential: any) => {
    if (addedQuestion.length > 0) {
      if (editId) {
        updateSuggestedQuestionsPool(editId, {
          _id: editId,
          ...credential,
          questions: addedQuestion,
        })
          .then(() => {
            setEditId("");
            reset();
            setAddedQuestion([]);
            toast.success("Updated Question Pool successfully!");
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      } else {
        createSuggestedQuestionsPool({
          ...credential,
          questions: addedQuestion,
        })
          .then(() => {
            toast.success("Created Question Pool successfully!");
            reset();
            setAddedQuestion([]);
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message);
          });
      }
    } else {
      setErrorMessage("At least One Question is required for Pool");
    }
  };
  useEffect(() => {
    getBoardRoomByLimit().then((res: any) => {
      setBoardRoom(res?.data?.data);
    });
  }, []);

  const onEdit = (index: number) => {
    const findQuestion = addedQuestion.find((_, key) => key === index);
    const filterQuestion = addedQuestion.filter((_, key) => key !== index);

    setValue("questions", findQuestion as string);
    setAddedQuestion(filterQuestion);
  };
  useEffect(() => {
    if (editId && boardRoom.length > 0) {
      getAllSuggestedQuestionsPool().then((res: any) => {
        const findQuestions = res?.data?.data.find(
          (item: any) => item._id === editId
        );

        // setQuestions(findQuestions);
        setValue("boardroomPoolId", findQuestions?.boardroomPoolId);
        setValue("name", findQuestions?.name);
        setValue("questions", findQuestions?.questions[0] as string);
        setAddedQuestion(findQuestions?.questions);
      });
    }
  }, [editId, boardRoom]);
  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(onQuestion)}>
        <div className="max-50 space-y-6">
          <AutoCompleteCommon
            error={errors?.boardroomPoolId}
            label="Choose BoardRoom:"
            listData={boardRoom?.map((item) => ({
              name: item?.boardroomName,
              value: item?._id,
            }))}
            value={watch("boardroomPoolId")}
            // value={{ value: getValues("boardroomPoolId") as String }}
            placeholder="Choose a BoardRoom"
            selectedDropDownVal={(val: any) => setValue("boardroomPoolId", val)}
          />
          <FormTextAreaField
            placeholder="Select pool name"
            name="name"
            label="Question Pool Name:"
            className="h-[80px]"
          />
          <FormTextAreaField
            placeholder="add questions"
            name="questions"
            label="Add question for the pool:"
            className="h-[130px]"
          />
          <div className=" flex justify-end ">
            <button
              type="button"
              className=" border border-black max-w-[100px] text-black h-[45px] text-f18 rounded-[10px] font-medium w-full"
              onClick={onAddQuestion}
            >
              Add
            </button>
          </div>
          <div className="questionaddwrap">
            {addedQuestion.length > 0 &&
              addedQuestion.map((question: any, index: any) => (
                <div className="questionbox" key={index}>
                  <p>{question}</p>
                  <span>
                    <Link to="#">
                      <Icon icon="bx:edit" onClick={() => onEdit(index)} />
                    </Link>
                    <Link onClick={() => onDelete(index)} to="#">
                      <Icon icon="material-symbols:delete-outline" />
                    </Link>
                  </span>
                </div>
              ))}
          </div>
          <ErrorMessage
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
          <button className="btn-black mt-5" onClick={onQuestion}>
            {editId ? "Update Question Pool" : " Create Question Pool"}
          </button>
        </div>
        <ToastContainer />
      </FormProvider>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddSuggestedQuestion;
