import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addPerk, updatePerkById } from "../../services/perk.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormSelectField from "../../common/hook-form/FormSelectField";
import FormCheckBoxField from "../../common/hook-form/FormCheckBox";
import { getProviderModel } from "../../services/provider-model.services";
import AutoCompleteCommon from "../../common/CommonAutoComplete";

interface Perk {
  id?: string | undefined | number;
  title: string;
  description: string;
  value: string | number;
  type?: string;
  activateOnRegistration: boolean;
  rule?: string;
  ruleValue?: string | null | number;
  provider?: string | null;
  model?: string | null;
}
interface ProviderModel {
  id?: string;
  model: string;
  provider: string;
}
/**
 * The Addtag component represents a form for adding a perk.
 * It allows the user to enter a title, description, and value for the perk.
 * The user can add a new perk or update an existing perk.
 */
const Addtag = ({ allPerk, setEditId, editId, errors }: any) => {
  // const [perk, setPerk] = useState<Perk>({
  //   id: "0",
  //   title: "",
  //   description: "",
  //   value: "",
  //   type: "",
  // });
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [inputChange, setInputChange] = useState<string>("");
  const [typeData, setTypeData] = useState([
    { value: "invite5", name: "Invite" },
    { value: "signup", name: "Sign up" },
    { value: "bonus", name: "Bonus" },
  ]);
  const editablePerk: string | null = localStorage.getItem("editPerk");
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required(""),
        value: Yup.string()
          .required("")
          .matches(/^0*[1-9]\d*$/),
        description: Yup.string().required(""),
        rule: Yup.string().required(""),
        type: Yup.string().required(""),
        ruleValue: Yup.string().when("rule", (val: any) => {
          const data = val[0];
          return data === "inviteAmount"
            ? Yup.string().required("Name is required")
            : Yup.string().notRequired();
        }),
      })
    ),
    defaultValues: {
      id: "0",
      title: "",
      value: "",
      description: "",
      type: "",
      rule: "",
      ruleValue: null,
      activateOnRegistration: false,
      provider: "",
      model: "",
    },
  });

  const { handleSubmit, reset, setValue, watch, trigger } = methods;
  // const handleInputChange = (
  //   event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  // ) => {
  //   const { name, value } = event.target;
  //   setPerk((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));
  // };
  useEffect(() => {
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);
  useEffect(() => {
    if (editId && editablePerk) {
      setValue("title", JSON.parse(editablePerk).title);
      setValue("description", JSON.parse(editablePerk).description);
      setValue("value", JSON.parse(editablePerk).value);
      setValue("rule", JSON.parse(editablePerk).rule);
      setValue("ruleValue", JSON.parse(editablePerk).ruleValue);
      setValue("type", JSON.parse(editablePerk).type);
      setValue(
        "activateOnRegistration",
        JSON.parse(editablePerk).activateOnRegistration
      );
      setValue(
        "activateOnRegistration",
        JSON.parse(editablePerk)?.provider ?? ""
      );
      setValue(
        "activateOnRegistration",
        JSON.parse(editablePerk).activateOnRegistration ?? ""
      );
      // setPerk({
      //   title: JSON.parse(editablePerk).title,
      //   description: JSON.parse(editablePerk).description,
      //   value: JSON.parse(editablePerk).value,
      // });
    }
  }, [editId]);

  const handleAdd = (formData: Perk) => {
    if (!editId) {
      if (allPerk && allPerk.length > 0) {
        const addData: Perk = {
          type: formData.type,
          title: formData.title,
          description: formData.description,
          value: Number(formData.value),
          activateOnRegistration: formData?.activateOnRegistration,
          ruleValue:
            formData?.rule === "inviteAmount"
              ? Number(formData?.ruleValue)
              : null,
          rule: formData?.rule,
          provider: formData?.provider,
          model: formData?.model,
        };
        addPerk(addData)
          .then((res) => {
            // setPerk({ title: "", description: "", value: "" });
            reset();
            toast.success("Perk Added");
          })
          .catch((err: any) => {
            toast.error(err.response.data.message);
            console.log(err);
          });
      } else {
        const addData: Perk = {
          id: 1,
          title: formData.title,
          type: formData.type,
          description: formData.description,
          value: Number(formData.value),
          activateOnRegistration: formData?.activateOnRegistration,
          ruleValue:
            formData?.rule === "inviteAmount"
              ? Number(formData?.ruleValue)
              : null,
          rule: formData?.rule,
          provider: formData?.provider,
          model: formData?.model,
        };
        localStorage.setItem("storeperk", JSON.stringify([addData]));
        // setPerk({ title: "", description: "", value: "" });
        reset();
      }
    } else {
      updatePerkById(editId, formData)
        .then(() => {
          localStorage.removeItem("editPerk");
          setEditId(null);
          // setPerk({ title: "", description: "", value: "" });
          reset();
          toast.success("Perk Updated");
        })
        .catch((err: any) => {
          toast.error(err.response.data.message);
          console.log(err);
        });
    }
  };
  return (
    <>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6">
          <FormTextField
            name="title"
            label="Title:"
            placeholder="Enter Title"
          />
          <FormTextAreaField
            name="description"
            label="Description:"
            placeholder="Enter Description"
          />
          <FormTextField
            name="value"
            label="Value:"
            placeholder="Enter Value"
          />
          <AutoCompleteCommon
            isEditable
            error={errors?.type}
            label="Type :"
            listData={typeData}
            value={watch("type")}
            placeholder="Select Model"
            selectedDropDownVal={(val: any) => {
              setValue("type", val);
              trigger("type");
            }}
            inputChangeHandler={(val: any) => {
              setInputChange(val);
            }}
            endEndadornment={
              <button
                type="button"
                className=" cursor-pointer border-2 text-f12 px-2 rounded-[10px] border-primary"
                onClick={() => {
                  if (inputChange !== "") {
                    const available = typeData.some(
                      (item) => item.name.toLowerCase() === inputChange.toLowerCase()
                    );
                    console.log(available, "check value");
                    
                    if (!available) {
                      let arr = [...typeData];
                      arr.push({ name: inputChange, value: inputChange });
                      setTypeData(arr);
                    }
                    setInputChange("");
                  }
                }}
              >
                add
              </button>
            }
          />
          <FormSelectField
            placeholder="Choose Perk Rule"
            label="Rule :"
            name="rule"
            option={[
              { value: "inviteAmount", name: "inviteAmount" },
              { value: "claimable", name: "claimable" },
            ]}
          />
          {watch("rule") === "inviteAmount" && (
            <FormTextField
              name="ruleValue"
              label="Rule Value :"
              type="number"
              placeholder="Enter Value"
            />
          )}
          <FormSelectField
            placeholder="Choose Provider"
            label="Choose Provider :"
            name="provider"
            option={providers?.map((item) => ({
              value: item.provider,
              name: item.provider,
            }))}
          />{" "}
          <AutoCompleteCommon
            disabled={watch("provider") === ""}
            error={errors?.model}
            label="Model:"
            listData={providers.flatMap((item: any) =>
              item?.provider === watch("provider")
                ? item?.models?.map((subModel: any) => ({
                    value: subModel,
                    name: subModel,
                  }))
                : []
            )}
            value={watch("model")}
            // defaultValue={{ value: getValues("model") }}
            placeholder="Select Model"
            selectedDropDownVal={(val: any) => {
              setValue("model", val);
              trigger("model");
            }}
          />
          <FormCheckBoxField
            label="Active on Registration:"
            name="activateOnRegistration"
          />
          <button
            className="btn-black mt-5"
            type="submit"
            // onClick={() => handleAdd(editId)}
          >
            {editId ? "Update" : "Add"}
          </button>
        </div>
      </FormProvider>
      <ToastContainer />
    </>
  );
};

export default Addtag;
