import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import {
  deleteCharacter,
  getCharactersBylimit,
} from "../../services/character.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Props {
  handleTabChange: (tab: string) => void;
  setEditCharacter: (character: IAICharacters) => void;
}

/**
 * The ViewCharacter component displays a list of AI characters.
 * The user can search for characters, edit or delete each character,
 * and navigate through pagination.
 */
const ViewCharacter: React.FC<Props> = ({
  handleTabChange,
  setEditCharacter,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string | null>(null);
  const [allCharacter, setAllCharacter] = useState<IAICharacters[]>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const onEditChatProvider = (character: IAICharacters) => {
    setEditCharacter(character);
    handleTabChange("first");
  };

  const onRemoveQuestion = () => {
    if (removeIndex !== null) {
      deleteCharacter(removeIndex)
        .then(() => {
          toast.success("Deleted Successfully!!");
          getCharacter();
          handleClose();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const onDelete = (index: string) => {
    setRemoveIndex(index);
    handleShow();
  };
  function getCharacter() {
    getCharactersBylimit(page, debouncedValue).then((res: any) => {
      setAllCharacter(res.data.data);
      setTotal(res?.data.total);
    });
  }

  useEffect(() => {
    getCharactersBylimit(page, debouncedValue).then((res: any) => {
      setAllCharacter(res.data.data);
      setTotal(res?.data.total);
    });
  }, [page, debouncedValue]);
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="max-50 view-content">
        <h3>Added AI characters</h3>
        <div className="custom-search">
          <input
            type="text"
            placeholder="Search"
            onChange={handleInputChange}
            value={inputValue}
          />
          <Link to="/">
            <img src="./assets/image/search.svg" />
          </Link>
        </div>
        {allCharacter.map((character, index) => (
          <div className="custom-card" key={index}>
            <div className="cardMediaAction">
              <Link to="#" onClick={() => onEditChatProvider(character)}>
                <Icon icon="bx:edit" />
              </Link>
              <Link onClick={() => onDelete(character._id)} to="#">
                <Icon icon="material-symbols:delete-outline" />
              </Link>
            </div>
            <div className="cardMedia AicardMedia">
              <span>
                <img src={character.logo} alt="Character" />
              </span>
              <h6>
                {character.name}
                <br />
                <i>{character.tags.filter((tag) => tag !== "").join(", ")}</i>
              </h6>
            </div>
            <p>{character.description}</p>
          </div>
        ))}
        <div className="pagination">
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
            disabled={page === 1}
            onClick={() => previous()}
          >
            {"<"}
          </button>
          <>{PagePagination()}</>
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
            disabled={page === TotalLength}
            onClick={() => next()}
          >
            {">"}
          </button>
        </div>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveQuestion}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewCharacter;
