import { useFormContext, Controller } from "react-hook-form";
import { cn } from "../../lib/utils";
export default function FormSwitchField({
  name,
  label,
  type,
  onChange = () => {},
  labelCss,
  ...other
}: any) {
  const { control, trigger } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <div className="custom-form space-x-3">
            {label && (
              <label
                className={`${!!error ? "text-error" : ""} ${labelCss}`}
                htmlFor={name}
              >
                {label}
              </label>
            )}
            <label
              className="relative inline-flex items-center cursor-pointer overflow-hidden"
              style={{ width: "fit-content" }}
            >
              <input
                id={name}
                // name={name}
                type="checkbox"
                className="sr-only peer"
                {...field}
                {...other}
                // onChange={field?.onChange}
                checked={field?.value}
              />
              <div className="w-[44px] h-[24px] bg-gray-200 peer-focus:outline-none peer-focus:ring-2 peer-focus:ring-blue-300 dark:peer-focus:ring-white rounded-full peer  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[16px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
              {/* {label && (
                <span className="ml-3 text-sm font-medium text-gray-900 dark:text-gray-300">
                  {label}
                </span>
              )} */}
            </label>
          </div>
          {/* {!!error && (
            <h3 className={"text-f12 text-error "}>{error?.message}</h3>
          )} */}
        </>
      )}
    />
  );
}
