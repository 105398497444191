import { useEffect, useMemo, useState } from "react";
import "./FeedBack.scss";
import NewFeedback from "../../component/NewFeedback/NewFeedback";
import { getFeedbackFilter } from "../../services/feedback.services";
import FeedbackHistory from "../../component/FeedbackHistory/FeedbackHistory";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";

interface Feedback {
  name: string;
  feedbackdate: string;
  feedbackdescription: string;
}

const FeedBack: React.FC = () => {
  const [show, setShow] = useState(false);
  const [activeTab, setActiveTab] = useState("first");
  const handleTabs = (key: string) => {
    setActiveTab(key);
  };
  const handleClose = () => setShow(false);
  const [role, setRole] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [editId, setEditId] = useState<string>("");
  const [viewAll, setViewAll] = useState<boolean>(false);
  // const handleShow = () => setShow(true);
  const [filterParameter, setFilterParameter] = useState<any>("");
  useEffect(() => {
    getFeedbackFilter()
      .then((res: any) => {
        setFilterParameter(res?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <>
      <div className="flex items-center justify-between feedback-select-wrap mx-auto">
        <div className="custom-form mb-4">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e: any) => setRole(e.target.value)}
          >
            <option value="">Type of user</option>
            {filterParameter &&
              filterParameter?.roles.length > 0 &&
              filterParameter?.roles.map((role: any) => (
                <option value={role}>{role}</option>
              ))}
          </select>
        </div>
        <div className="custom-form mb-4">
          <select
            className="form-select"
            aria-label="Default select example"
            onChange={(e: any) => setType(e.target.value)}
          >
            <option value="">Type of feedback</option>
            {filterParameter &&
              filterParameter?.types.length > 0 &&
              filterParameter?.types.map((type: any) => (
                <option value={type}>{type}</option>
              ))}
          </select>
        </div>
      </div>
      <div className="Homepagecontent pt-4">
        <Tabs defaultValue="first" className="w-full">
          <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
            <TabsTrigger value="first">New Messages</TabsTrigger>
            <TabsTrigger value="second">Feedback History</TabsTrigger>
          </TabsList>
          <TabsContent value="first" className="p-[30px]">
            <NewFeedback
              role={role}
              type={type}
              setActiveTab={setActiveTab}
              setViewAll={setViewAll}
              setEditId={setEditId}
              editId={editId}
            />
          </TabsContent>
          <TabsContent value="second" className="p-[30px]">
            <FeedbackHistory
              role={role}
              type={type}
              viewAll={viewAll}
              setViewAll={setViewAll}
              setEditId={setEditId}
              editId={editId}
            />
          </TabsContent>
        </Tabs>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={handleClose}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default FeedBack;
