import http from "../http/request";

function addPerk(body: any) {
  return new Promise((resolve, reject) => {
    http(`/perks`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getPerks() {
  return new Promise((resolve, reject) => {
    http(`/perks`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deletePerkById(id: string) {
  return new Promise((resolve, reject) => {
    http(`/perks/${id}`, "delete")
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updatePerkById(id: number | null, body: any) {
  return new Promise((resolve, reject) => {
    http(`/perks/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deletePerkByUser(body: any) {
  return new Promise((resolve, reject) => {
    http(`/perks/removePerkFromUser`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function addPerkToUser(body: any) {
  return new Promise((resolve, reject) => {
    http(`/perks/addPerkToUser`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function addCreditItemToUser(payload: any) {
  return new Promise((resolve, reject) => {
    http(`/credits-config-user/giveCreditsToUser`, "post", payload)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getAllPerkByUser(page: any, userId?: any) {
  return new Promise((resolve, reject) => {
    http(
      `/users/perks?page=${page}&limit=5${
        userId ? `&accountId=${userId}` : ""
      }`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  addPerk,
  getPerks,
  deletePerkById,
  updatePerkById,
  addPerkToUser,
  addCreditItemToUser,
  getAllPerkByUser,
  deletePerkByUser,
};
