import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addProviderModel,
  getProviderModel,
} from "../../services/provider-model.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormSelectField from "../../common/hook-form/FormSelectField";

interface ProviderModel {
  id?: string;
  model: string;
  provider: string;
}

const AddModel: React.FC = () => {
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        model: Yup.string().required(""),
        provider: Yup.string().required(""),
      })
    ),
    defaultValues: {
      model: "",
      provider: "",
    },
  });

  const { handleSubmit, reset } = methods;
  useEffect(() => {
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);

  const handleAdd = (formData: any) => {
    addProviderModel(formData)
      .then(() => {
        toast.success("Model Added Successful!!");
        reset();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(handleAdd)}>
        <div className="max-50 space-y-6 ">
          <FormSelectField
            placeholder="Choose Provider"
            label="Choose Provider :"
            name="provider"
            option={providers?.map((item) => ({
              value: item.provider,
              name: item.provider,
            }))}
          />
          <FormTextField name="model" label="model:" placeholder="Model" />
          <button type="submit" className="btn-black mt-5">
            Add{" "}
          </button>
        </div>
      </FormProvider>
      {/* <Form className="max-50" onSubmit={(e) => handleAdd(e)}>
        <Form.Group
          className="custom-form mb-4"
          style={{ maxWidth: "400px", margin: "0 auto" }}
        >
          <div className="w-100">
            <Form.Select
              aria-label="Default select example"
              value={modals?.provider}
              onChange={(e: any) =>
                setModals({ ...modals, provider: e.target.value })
              }
            >
              <option>Choose Provider</option>
              {providers.length > 0 &&
                providers.map((provider) => (
                  <option value={provider?.provider}>
                    {provider?.provider}
                  </option>
                ))}
            </Form.Select>
          </div>
        </Form.Group>
        <Form.Group className="custom-form mb-4 justify-content-center">
          <Form.Label style={{ maxWidth: "unset", width: "auto" }}>
            model:
          </Form.Label>
          <Form.Control
            type="text"
            name="model"
            value={modals?.model}
            onChange={handleInputChange}
            style={{ maxWidth: "160px", marginLeft: "20px" }}
          />
        </Form.Group>
        <button type="submit" className="btn-black mt-5">
          {editId ? "Update" : "Add"}
        </button>
      </Form> */}
    </>
  );
};

export default AddModel;
