// import React from "react";
// import ReactDOM from "react-dom";
import { createRoot } from "react-dom/client";
import { StrictMode } from "react";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root: HTMLElement | null = document.getElementById("root");

if (root) {
  createRoot(root).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

reportWebVitals();
