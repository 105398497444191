import { useState, useMemo } from "react";
import Addtag from "../../component/Addtag/Addtag";
import Viewtags from "../../component/Viewtags/Viewtags";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * Component for managing tags.
 * @component
 * @returns {JSX.Element} Tagpage component
 */
const Tagpage = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [editId, setEditId] = useState<string>("");

  /**
   * Fetches data from local storage and updates the state.
   */

  /**
   * Event handler for tab change.
   * @param {string} tab - The new active tab.
   */
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  // Initial data fetch when component mounts
  // useEffect(() => {
  //   fetchDataFromLocalStorage();
  // }, []);

  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="first" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a tag</TabsTrigger>
          <TabsTrigger value="second">View all tags</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <Addtag setEditId={setEditId} editId={editId} />
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <Viewtags setEditId={setEditId} handleTabChange={handleTabChange} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Tagpage;
