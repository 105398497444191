import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "./CreditPack.scss";
import TrialPack from "../../component/TrialPack/TrialPack";
import { getAllProducts } from "../../services/pack.services";
import ViewAllPacks from "../../component/ViewAllPacks/ViewAllPacks";
import { getProviderModel } from "../../services/provider-model.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";

interface CreditPack {
  // Define the properties of a credit pack
  // Add the necessary types for each property
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const CreditPack: React.FC = () => {
  const [trialPack, setTrialPack] = useState(false);
  const [eventType, setEventType] = useState("subscription");
  const [packs, setPacks] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const [editId, setEditId] = useState<string>("");
  const [type, setType] = useState<any>("");

  useEffect(() => {
    getAllProducts(eventType, page, type)
      .then((res: any) => {
        setPacks(res?.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [eventType, page, type]);

  const packHandler = (item: string) => {
    setTrialPack(true);
    setEditId(item);
  };
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);

    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };

  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength?.length - 1) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <div className="Homepagecontent">
        <h3 className=" text-center pb-4">
          <b>Packs</b>
        </h3>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "30px",
          }}
        >
          <div className="custom-form" style={{ width: "400px" }}>
            <select
              className="form-select"
              aria-label="Default select example"
              name="tags"
              onChange={(e: any) => setType(e.target.value)}
            >
              <option value="">Select a platform</option>
              <option value="google">Google</option>
              <option value="apple">Apple</option>
            </select>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              width: "50%",
            }}
          >
            <div className="space-x-2">
              <input
                className="form-check-input"
                type="radio"
                id="Subscription"
                onChange={() => {
                  setEventType("subscription");
                  setTrialPack(false);
                }}
                checked={eventType === "subscription"}
              />
              <label htmlFor="Subscription">Subscription</label>
            </div>
            <div className="space-x-2">
              <input
                className="form-check-input"
                type="radio"
                id="Credits"
                onChange={() => {
                  setEventType("purchase");
                  setTrialPack(false);
                }}
                checked={eventType === "purchase"}
              />
              <label htmlFor="Credits">Credits</label>
            </div>
          </div>
        </div>
        <Tabs defaultValue="first" className="w-full">
          <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
            <TabsTrigger value="first">Add a Pack</TabsTrigger>
            <TabsTrigger value="second">View all packs</TabsTrigger>
          </TabsList>
          <TabsContent value="first" className="p-[30px]">
            {trialPack ? (
              <TrialPack editId={editId} setEditId={setEditId} />
            ) : (
              <>
                {packs?.length > 0 &&
                  packs.map((pack: any) => (
                    <>
                      <div
                        onClick={() => packHandler(pack?.id)}
                        className={`creditpack-type-box flex items-center justify-between  ${
                          pack?.isActive ? "bg-white" : "bg-gray-500 text-white"
                        }`}
                      >
                        <div>
                          <div className="flex space-x-8">
                            <h5 className="">{pack?.title}</h5>
                            {(pack?.type || pack?.type !== "") && (
                              <h5 className="">
                                {pack?.type === "apple"
                                  ? "[Apple]"
                                  : "[Google]"}
                              </h5>
                            )}
                          </div>
                          <p className="">
                            Price:
                            <span className="ps-3">{pack?.price}</span>
                          </p>
                        </div>
                        <div className="">
                          <svg
                            width="12"
                            height="15"
                            viewBox="0 0 12 15"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.27027 15L0 13.25L7.45946 7.5L0 1.75L2.27027 0L12 7.5L2.27027 15Z"
                              fill="#2E2D32"
                            />
                          </svg>
                        </div>
                      </div>
                    </>
                  ))}
                <div className="pagination">
                  <button
                    className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
                    disabled={page === 1}
                    onClick={() => previous()}
                  >
                    {"<"}
                  </button>
                  <>{PagePagination()}</>
                  <button
                    className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
                    disabled={page === TotalLength}
                    onClick={() => next()}
                  >
                    {">"}
                  </button>
                </div>
              </>
            )}
          </TabsContent>
          <TabsContent value="second" className="p-[30px]">
            {trialPack ? (
              <TrialPack editId={editId} setEditId={setEditId} />
            ) : (
              <ViewAllPacks
                eventType={eventType}
                packHandler={packHandler}
                type={type}
              />
            )}
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};
export default CreditPack;
