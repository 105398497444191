import { useEffect, useMemo, useState } from "react";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import Addsuggestedboardrooms from "../../component/Addsuggestedboardrooms/Addsuggestedboardrooms";
import Viewsuggestedboardrooms from "../../component/Viewsuggestedboardrooms/Viewsuggestedboardrooms";
import { getAllCharacter } from "../../services/character.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
/**
 * The SuggestedBoardrooms component displays a tabbed interface for adding and viewing suggested boardrooms.
 * The user can switch between tabs to add a boardroom or view all boardrooms.
 */
const SuggestedBoardrooms: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [allCharacter, setAllCharacter] = useState<IAICharacters[]>([]);
  const [editId, setEditId] = useState<string | null>(null);

  const getAllCharacters = () => {
    getAllCharacter()
      .then((characters: any) => {
        setAllCharacter(characters?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (activeTab === "first") {
      getAllCharacters();
    }
  }, [activeTab]);
  return (
    <div className="Homepagecontent">
      <Tabs
        value={activeTab}
        onValueChange={(val) => setActiveTab(val)}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a boardroom</TabsTrigger>
          <TabsTrigger value="second">View all boardroom</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <Addsuggestedboardrooms
            allCharacter={allCharacter}
            editId={editId}
            setEditId={setEditId}
          />{" "}
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <Viewsuggestedboardrooms
            setActiveTab={setActiveTab}
            setEditId={setEditId}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SuggestedBoardrooms;
