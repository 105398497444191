import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  addGlobalProduct,
  deleteGlobalProduct,
  getAllGlobalProductByProductId,
  getProductById,
  updateGlobalProduct,
  updateProductById,
} from "../../services/pack.services";
import { getProviderModel } from "../../services/provider-model.services";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import FormTextField from "../../common/hook-form/FormTextField";
import FormSwitchField from "../../common/hook-form/FormSwitchField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import FormSelectField from "../../common/hook-form/FormSelectField";

/**
 * The TrialPack component represents a form for adding a basic trial pack.
 * It allows the user to enter the expiry, number of credits, original price, price after discount,
 * text for the label, prompt size, and description of the trial pack.
 * The user can add the trial pack and a success toast notification will be shown.
 */
interface IProps {
  editId: string;
  setEditId: (id: string) => void;
}

interface ProviderModel {
  id?: string;
  models: string[];
  provider: string;
}

const TrialPack: React.FC<IProps> = ({ editId, setEditId }) => {
  const [pack, setPack] = useState<any>({});
  const [global, setGlobal] = useState<any>({ provider: "" });
  const [allGlobal, setAllGLobal] = useState<any>([]);
  const [editGlobal, setEditGlobal] = useState("");
  const [addGlobal, setAddGlobal] = useState(false);
  const [providers, setProviders] = useState<ProviderModel[]>([]);

  //react-hook-form *************
  //*****************************
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        // boardName: Yup.string().required(""),
        description: Yup.string().required(""),
        // characterId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      expiraryInNumber: "",
      expiraryInDate: "",
      bundleId: "",
      description: "",
      duration: null,
      eventType: "",
      expiresAt: null,
      id: "",
      isActive: true,
      isRetired: false,
      itemId: "",
      label: null,
      price: null,
      priceAfterDiscount: null,
      priority: 0,
      productId: "",
      state: "",
      title: "",
      type: "",
      boardName: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = methods;

  //react-hook-form *************
  //*****************************

  //react-hook-form *************
  //*****************************
  const addGlobalItemMethods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        title: Yup.string().required(""),
        description: Yup.string().required(""),
        model: Yup.string().required(""),
        provider: Yup.string().required(""),
        rank: Yup.string()
          .required("")
          .matches(/^0*[1-9]\d*$/),
        credits: Yup.string()
          .required("")
          .matches(/^0*[1-9]\d*$/),
        promptSize: Yup.string()
          .required("")
          .matches(/^0*[1-9]\d*$/),
        // characterId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      title: "",
      description: "",
      model: "",
      provider: "",
      rank: "",
      credits: "",
      promptSize: "",
    },
  });

  const {
    handleSubmit: globalItemSubmitHandler,
    reset: globalItemFormReset,
    setValue: setGlobalValue,
    watch: getGlobalValue,
  } = addGlobalItemMethods;

  //react-hook-form *************
  //*****************************
  const isExpireIsDate = (data: any) => {
    const dateObject = new Date(data);
    if (!isNaN(dateObject.getTime()) && data !== "Invalid Date") {
      setValue("expiraryInDate", data?.slice(0, 10));
    } else {
      (typeof data === "string" || typeof data === "number") &&
        setValue("expiraryInNumber", data);
    }
  };
  const handleAddButtonClick = (formData: any) => {
    const { createdAt, updatedAt, expiresAt, duration, ...rest } = formData;
    updateProductById(editId, {
      ...rest,
      expiraryInNumber: undefined,
      expiraryInDate: undefined,
      // expiresAt:
      //   formData?.expiraryInDate !== ""
      //     ? formData?.expiraryInDate
      //     : formData?.expiraryInNumber,
    })
      .then(() => {
        setEditId("");
        setPack({});
        toast.success("Trial pack added successfully!");
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.message);
      });
  };

  useEffect(() => {
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);
  function getAllData() {
    if (editId) {
      getProductById(editId)
        .then((res: any) => {
          setPack(res?.data.data);
          reset({ ...res?.data.data });
          isExpireIsDate(res?.data.data?.expiresAt);
          getAllGlobalProductByProductId(res?.data.data.productId)
            .then((res: any) => {
              setAllGLobal(res?.data.data);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
  useEffect(() => {
    getAllData();
  }, [editId]);

  const handleAddGlobal = (formData: any) => {
    if (editGlobal) {
      updateGlobalProduct(editGlobal, {
        ...formData,
        productId: pack?.productId,
        eventType: pack?.eventType,
      })
        .then(() => {
          // setGlobal({ provider: "" });
          toast.success("Global Item Updated!!");
          globalItemFormReset();
          getAllData();
          setEditGlobal("");
          setAddGlobal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      addGlobalProduct({
        ...formData,
        productId: pack?.productId,
        eventType: pack?.eventType,
      })
        .then(() => {
          globalItemFormReset();
          // setGlobal({ provider: "" });
          toast.success("Global Item Added!!");
          getAllData();
          setAddGlobal(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const handleEditGlobal = (id: any) => {
    setAddGlobal(true);
    const editGlobal = allGlobal?.find((item: any) => item.id === id);
    setGlobalValue("title", editGlobal?.title);
    setGlobalValue("description", editGlobal?.description);
    setGlobalValue("model", editGlobal?.model);
    setGlobalValue("provider", editGlobal?.provider);
    setGlobalValue("rank", editGlobal?.rank);
    setGlobalValue("credits", editGlobal?.credits);
    setGlobalValue("promptSize", editGlobal?.promptSize);
    setEditGlobal(id);
  };
  const handleDeleteGlobal = (id: any) => {
    deleteGlobalProduct(id)
      .then(() => {
        setGlobal({ provider: "" });
        toast.success("Global Item Deleted!!");
        getAllData();
        setEditGlobal("");
        setAddGlobal(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const handleSwitch = (event: any) => {
  //   const { name, checked } = event.target;
  //   setPack({ ...pack, [name]: checked });
  // };
  console.log(getGlobalValue("provider"),"check global value");
  
  return (
    <div className=" grid grid-cols-8 gap-4">
      <ToastContainer />
      <div className=" col-span-5">
        <div className="trialpack-heading">
          <h2>{pack?.title}</h2>
          <p className="flex items-center justify-center gap-2">
            Price:<span>{pack?.price}</span>
          </p>
          <p className="flex items-center justify-center gap-2 pt-3">
            {(pack?.type || pack?.type !== "") && (
              <h5 className="">
                {pack?.type === "apple" ? "[Apple]" : "[Google]"}
              </h5>
            )}
          </p>
        </div>
        <FormProvider methods={methods}>
          <div className="max-50 space-y-6">
            <div className="custom-form w-full">
              <label>Expiry:</label>
              <div className="w-full">
                <FormTextField name="expiraryInNumber" type="number" />
                <span className="expiry-option">or</span>
                <FormTextField name="expiraryInDate" type="date" />
              </div>
            </div>
            <FormTextField
              name=""
              label="No. of credits:"
              type="number"
              readOnly
            />
            <FormTextField
              placeholder="Orignal Price"
              name="price"
              label="Original Price:"
              type="number"
            />
            <FormTextField
              placeholder="Price After Discount"
              name="priceAfterDiscount"
              label="Price After Discount:"
              type="number"
            />
            <FormTextField
              placeholder="Enter Text"
              name="label"
              label="Text for the label:"
              type="text"
            />{" "}
            <FormTextField
              name=""
              label="Prompt Size:"
              type="number"
              readOnly
            />
            <FormSwitchField label="Go Lived:" name="isActive" />
            <FormSwitchField label="Retired:" name="isRetired" />
            <FormTextAreaField
              placeholder="Enter Text"
              name="description"
              label="Description:"
              type="text"
            />
          </div>
        </FormProvider>
        <>
          {allGlobal?.length > 0 &&
            allGlobal.map((item: any) => (
              <div
                style={{
                  borderRadius: "10px",
                  border: "0.25px solid #fff",
                  background: "#FFF",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                  padding: "20px 40px",
                  position: "relative",
                  margin: "40px 0",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "10px",
                    top: "10px",
                    display: "flex",
                    gap: "8px",
                  }}
                >
                  <Link to="#" onClick={() => handleEditGlobal(item?.id)}>
                    <Icon icon="bx:edit" fontSize={"20px"} />
                  </Link>
                  <Link to="#" onClick={() => handleDeleteGlobal(item?.id)}>
                    <Icon
                      icon="material-symbols:delete-outline"
                      fontSize={"20px"}
                    />
                  </Link>
                </div>
                <div className="creditpack-detail">
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Title:</h5>
                    <p className="park-description">{item?.title}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Description:</h5>
                    <p>{item?.description}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Chat Provider:</h5>
                    <p className="park-description">{item?.provider}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Model:</h5>
                    <p>{item?.model}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Rank:</h5>
                    <p>{item?.rank}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Credits:</h5>
                    <p>{item?.credits}</p>
                  </div>
                  <div className="flex items-start mt-3 gap-4">
                    <h5>Prompt Size:</h5>
                    <p>{item?.promptSize}</p>
                  </div>
                </div>
              </div>
            ))}
        </>
        <div className=" mb-10 flex justify-end">
          <button
            className=" bg-[#2e2d32] font-semibold rounded-[8px] text-white px-4 py-2"
            onClick={() => setAddGlobal(!addGlobal)}
          >
            Add Global Item Base
          </button>
        </div>
        {addGlobal && (
          <div
            style={{
              borderRadius: "10px",
              border: "0.25px solid #fff",
              background: "#FFF",
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
              padding: "40px 25px",
              position: "relative",
            }}
            className=" my-4"
          >
            <>
              {editGlobal && (
                <Link
                  to="#"
                  style={{ position: "absolute", right: "10px", top: "10px" }}
                  onClick={() => handleDeleteGlobal(editGlobal)}
                >
                  <Icon
                    icon="material-symbols:delete-outline"
                    fontSize={"20px"}
                  />
                </Link>
              )}
            </>
            {/* title:"",description:"",model:"",provider:"",rank:"",credits:"",promptSize:"" */}
            <h5>
              <b>Global Item Base</b>
            </h5>
            <FormProvider
              methods={addGlobalItemMethods}
              onSubmit={globalItemSubmitHandler(handleAddGlobal)}
            >
              <div className="max-w-lg m-auto py-3 space-y-6">
                <FormTextAreaField
                  placeholder="Enter the Title:"
                  name="title"
                  label="Title:"
                  type="number"
                />
                <FormTextAreaField
                  style={{ height: "120px" }}
                  placeholder="Enter the description"
                  name="description"
                  label="Description:"
                  type="number"
                />
                <FormSelectField
                  name="provider"
                  label="Select a Provider"
                  option={
                    providers?.length > 0
                      ? providers?.map((item) => ({
                          name: item.provider,
                          value: item?.provider,
                        }))
                      : []
                  }
                />
                <FormSelectField
                  disabled={getGlobalValue("provider") === ""}
                  placeholder="Select Model"
                  name="model"
                  label="Select a Model"
                  option={providers.flatMap((item: any) =>
                    item?.provider === getGlobalValue("provider")
                      ? item?.models?.map((subModel: any) => ({
                          value: subModel,
                          name: subModel,
                        }))
                      : []
                  )}
                />
                <FormTextField
                  name="rank"
                  label="Rank:"
                  type="number"
                  placeholder="Rank"
                />
                <FormTextField
                  name="credits"
                  label="Credits:"
                  type="number"
                  placeholder="Credits"
                />
                <FormTextField
                  name="promptSize"
                  label="Prompt Size:"
                  type="number"
                  placeholder="Prompt Size"
                />
                <button className="btn-black mt-5">
                  {editGlobal ? "Update" : "Add"}
                </button>
              </div>
            </FormProvider>
          </div>
        )}
        <button
          onClick={handleSubmit(handleAddButtonClick)}
          className="btn-black mt-5"
        >
          Update
        </button>
      </div>
      <div className="col-span-2">
        <img
          alt="unlock-chat-credit"
          src="./assets/image/unlockchatcredit.png"
          className="w-fit  object-cover"
        />
      </div>
    </div>
  );
};

export default TrialPack;
