import React, { useEffect, useRef } from "react";
import { cn } from "../lib/utils";

const   AutoCompleteCommon = ({
  name,
  label,
  type,
  className,
  value,
  textInputCss,
  outerDivStyle,
  endEndadornment,
  defaultValue = { value: "" },
  inputChangeHandler = () => {},
  customizeSelect,
  listData,
  labelCss,
  placeholder,
  selectedDropDownVal,
  error,
  ...other
}: any) => {
  const inputRef: any = useRef();
  const [focus, setFocus] = React.useState(false);
  const [dropDownVal, setDropDownVal] = React.useState(listData ?? []);
  const [fieldValue, setFieldValue] = React.useState<any>("");
  useEffect(() => {
    setDropDownVal(listData);
    switch (value) {
      case "":
        setFieldValue("");
        break;
      case undefined:
        setFieldValue("");
        break;
      default:
        const selectVal = dropDownVal?.find(
          (item: any) => item?.value === value
        );
        setFieldValue(selectVal?.name ?? "");
        break;
    }
  }, [listData, value]);

  const listChangeHandler = (item: any) => {
    selectedDropDownVal(item?.value);
    // setFieldValue(item?.name);
    setFocus(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (inputRef.current && !inputRef.current?.contains(event.target)) {
        setFocus(false);
        const fieldExistsInList = listData?.some(
          (item: any) => item.name === fieldValue
        );
        if (!fieldExistsInList) {
          // selectedDropDownVal("");
          // setFieldValue("");
          setDropDownVal(listData);
        }
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [listData, fieldValue]);

  const valueChangeHandler = (event: any) => {
    inputChangeHandler(event?.target?.value);
    setFieldValue(event?.target?.value);
    const filteredData = listData?.filter((item: any) =>
      item?.name?.toLowerCase().includes(event?.target?.value?.toLowerCase())
    );
    setDropDownVal(filteredData);
  };

  return (
    <div className={cn(`custom-form ${className}`)}>
      {label && (
        <label
          className={`${!!error ? "text-error" : ""} ${labelCss}`}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <div ref={inputRef} className="relative w-full">
        <div className={`${outerDivStyle}`}>
          <input
            name={name}
            placeholder={placeholder}
            value={fieldValue}
            onFocus={() => setFocus(true)}
            className={cn(
              `w-full disabled:bg-[#e9ecef] ${
                !!error
                  ? "focus:border-error border-2 border-error"
                  : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5]"
              } ${textInputCss}`
            )}
            type={type}
            onChange={valueChangeHandler}
            {...other}
          />
          {endEndadornment && (
            <div className="absolute inset-y-0 right-10 flex items-center pl-3">
              {endEndadornment}
            </div>
          )}
          <div
            onClick={() => setFocus(true)}
            className="absolute cursor-pointer inset-y-0 right-0 flex items-center pr-3"
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                fill="currentColor"
                fill-rule="evenodd"
                clip-rule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
        {focus && (
          <div className=" absolute w-full z-10">
            {dropDownVal.length > 0 ? (
              <div
                className="max-h-[250px] w-full gap-0 list-none overflow-y-auto py-1 rounded-[5px] border  my-2 bg-white "
                style={{ display: "block !important" }}
              >
                {dropDownVal &&
                  dropDownVal?.map((item: any) => (
                    <li
                      key={item.value}
                      value={item?.value}
                      className={` cursor-pointer hover:opacity-3 px-2 py-2 w-full`}
                      onClick={() => listChangeHandler(item)}
                    >
                      {item?.name}
                    </li>
                  ))}
              </div>
            ) : (
              <div className="px-3 py-3 bg-white rounded-md text-primary my-3">
                <p className="text-f12">No Data available</p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AutoCompleteCommon;
