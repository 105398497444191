import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getFeedbackAllAnswerByLimit } from "../../services/feedback.services";

interface IProps {
  editId: string;
  setEditId: (id: string) => void;
  handleFeedback: (feed: any) => void;
  role: string;
  type: string;
  setViewAll: (id: boolean) => void;
  setEdit: (id: boolean) => void;
}

const ViewAllFeedback: React.FC<IProps> = ({
  setEditId,
  role,
  type,
  handleFeedback,
  setViewAll,
  editId,
  setEdit,
}) => {
  const [allFeedback, setAllFeedback] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");

  const handleClick = (feed: any) => {
    handleFeedback(feed);
    setViewAll(false);
    setEditId(feed?.user.id);
    setEdit(true);
  };
  function getNotAnswer() {
    getFeedbackAllAnswerByLimit(page, role, type, editId)
      .then((res: any) => {
        setAllFeedback(res?.data.data);
        setTotal(res?.data.total);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  useEffect(() => {
    getNotAnswer();
  }, [page, role, type, editId]);
  console.log("viewAll,", editId, allFeedback);

  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);
  
    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }
  
    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };
  
  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength?.length - 1) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <ToastContainer />
      <>
        {allFeedback.length > 0 &&
          allFeedback.map((feed: any) => (
            <div
              style={{
                borderRadius: "10px",
                border: "0.25px solid #fff",
                background: "#FFF",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                padding: "40px 25px",
                position: "relative",
              }}
            >
              view all feedback
              <span className="flex justify-end">
                {feed?.createdAt.slice(0, 10)}
              </span>
              <div className="custom-form pt-3">
                <label>{feed?.user.username}:</label>
                <input
                  type="textarea"
                  style={{ height: "140px" }}
                  placeholder="Enter the title"
                  className="w-50"
                  value={feed?.feedback}
                  readOnly
                />
              </div>
              <div className="custom-form pt-3">
                <label>Team Response:</label>
                <input
                  type="textarea"
                  style={{ height: "180px" }}
                  placeholder="Enter your Response here..."
                  className="w-50"
                  name="answer"
                  value={feed?.answer}
                  readOnly
                />
              </div>
              <div className="flex justify-end pb-3">
                <Link to="#" onClick={() => handleClick(feed)}>
                  <Icon icon="bx:edit" fontSize={"20px"} />
                </Link>
              </div>
            </div>
          ))}
      </>
      <div className="pagination">
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
          disabled={page === 1}
          onClick={() => previous()}
        >
          {"<"}
        </button>
        <>{PagePagination()}</>
        <button
          className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
          disabled={page === TotalLength}
          onClick={() => next()}
        >
          {">"}
        </button>
      </div>
    </>
  );
};

export default ViewAllFeedback;
