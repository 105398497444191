import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ICategories } from "../../api/interfaces/categories";
import {
  addLinkCategorytoPool,
  getAllCategoryQuestionsPool,
  getCategories,
} from "../../services/categories.services";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import { ICategoryQuestionPool } from "../../api/interfaces/questionPools";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import ErrorMessage from "../../common/ErrorMessage";

const LinkQuestion: React.FC = () => {
  const [show, setShow] = useState(false);
  const [questionPool, setQuestionPool] = useState<ICategoryQuestionPool[]>([]);
  const [suggestedPool, setSuggestedPool] = useState<null | string>(null);
  const [error, setError] = useState<string>("");
  const [category, setCategory] = useState<Partial<ICategories[]>>([]);
  // const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [removeIndex, setRemoveIndex] = useState<number | null>(null);
  const [question, setQuestion] = useState<Partial<ICategoryQuestionPool>>({
    _id: "",
  });
  // react hook form

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        selectedCategory: Yup.string().required(""),
      })
    ),
    defaultValues: {
      selectedCategory: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
    reset,
  } = methods;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSelectOption = (val: any) => {
    if (val !== "" && val !== "Select question pool") {
      const findQuestion = questionPool.find((item) => item._id === val);
      setQuestion((prev) => ({ ...prev, ...findQuestion }));
    }
  };

  const onRemoveQuestionPool = () => {
    if (removeIndex !== null) {
      setQuestion({ _id: "" });
      handleClose();
    }
  };

  const onDelete = (index: any) => {
    setRemoveIndex(index);
    handleShow();
  };

  const onFinish = (formData: any) => {
    // setValue("selectedCategory", "");
    if (question?._id !== "" && formData?.selectedCategory) {
      addLinkCategorytoPool(
        formData?.selectedCategory,
        question?._id as any
      ).then(() => {
        setQuestion({ _id: "" });
        reset();
        toast.success("Finish Link successfully!");
      });
    } else {
      question?._id === "" && setError("field is Required");
      return;
    }
  };
  useEffect(() => {
    getCategories().then((res: any) => {
      setCategory(res?.data.data);
      const linkedPool = res?.data.data?.find(
        (item: any) => item?._id === getValues("selectedCategory")
      );
      linkedPool?.suggestionsPool?.name
        ? setSuggestedPool(linkedPool?.suggestionsPool?.name)
        : setSuggestedPool(null);
    });
  }, [question, watch("selectedCategory")]);

  const selectedCategoryHandler = (val: string | number) => {
    // const linkedPool = category?.find((item) => item?._id === val);
    // linkedPool?.suggestionsPool?.name
    //   ? setSuggestedPool(linkedPool?.suggestionsPool?.name)
    //   : setSuggestedPool(null);
    getAllCategoryQuestionsPool().then((res: any) => {
      setQuestionPool(
        res?.data?.data?.filter(
          (item: any) => item?.name !== "" && item?.categoryId === val
        )
      );
      // setQuestionPool(res?.data.data);
    });

    setValue("selectedCategory", val);
  };
  return (
    <>
      <ToastContainer />
      <div className="Homepagecontent">
        <Tabs defaultValue="first" className="w-full">
          <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
            <TabsTrigger value="first">Link a question Pool</TabsTrigger>
          </TabsList>
          <TabsContent value="first" className="p-[30px]">
            <FormProvider methods={methods} onSubmit={handleSubmit(onFinish)}>
              <div className="space-y-6 max-50">
                {getValues("selectedCategory") && (
                  <div className="flex justify-between">
                    <h2 className="text-f20 font-medium">
                      Previously linked pool:
                    </h2>
                    <h2 className="text-f20 font-medium">
                      {suggestedPool ? suggestedPool : "Not Specified"}
                    </h2>
                  </div>
                )}

                <AutoCompleteCommon
                  error={errors?.selectedCategory}
                  label="Category:"
                  listData={category?.map((item) => ({
                    name: item?.name,
                    value: item?._id,
                  }))}
                  value={getValues("selectedCategory")}
                  placeholder="Choose a Category"
                  selectedDropDownVal={selectedCategoryHandler}
                />
                <AutoCompleteCommon
                  // error={errors?.selectedCategory}
                  label="Question Pool:"
                  listData={questionPool?.map((item) => ({
                    name: item?.name,
                    value: item?._id,
                  }))}
                  // value={getValues("selectedCategory")}
                  placeholder="Select question pool"
                  selectedDropDownVal={onSelectOption}
                />
                <ul className="link-question-list">
                  {question._id !== "" && (
                    <li>
                      {question.name}{" "}
                      <Link onClick={() => onDelete(question?._id)} to="#">
                        <Icon icon="carbon:close-outline" />
                      </Link>
                    </li>
                  )}
                </ul>
                <ErrorMessage errorMessage={error} setErrorMessage={setError} />
                <button className="btn-black mt-5" type="submit">
                  Finish Linking{" "}
                </button>
              </div>
            </FormProvider>
          </TabsContent>
        </Tabs>
        <Dialog open={show} onOpenChange={setShow}>
          <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
            <DialogHeader>
              <p>Are you sure?</p>
            </DialogHeader>
            <DialogFooter className="modal-footer ">
              <button className="btn-black-border btn" onClick={handleClose}>
                Cancel
              </button>
              <button className="btn-black" onClick={onRemoveQuestionPool}>
                Delete
              </button>{" "}
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default LinkQuestion;
