import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { ICategories } from "../../api/interfaces/categories";
import { ISystemPrompt } from "../../api/interfaces/systemPrompt";
import { getCategories } from "../../services/categories.services";
import { getProviderModel } from "../../services/provider-model.services";
import {
  addSystemPrompt,
  individualSystemPrompt,
  updateSystemPrompt,
} from "../../services/systemPrompt";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import FormCheckBoxField from "../../common/hook-form/FormCheckBox";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormSelectField from "../../common/hook-form/FormSelectField";

interface IProps {
  editId: string | null;
  setEditId: (id: string | null) => void;
}
interface ProviderModel {
  id?: string;
  models: string[];
  provider: string;
}
// const yamlValidationRegex =
//   /^[\s\S]*?(\w+\s*:\s*\w+|\w+\s*:\s*\w+\s*(\n|$))+[\s\S]*?$/;

const AddSystemPromptCategories: React.FC<IProps> = ({ editId, setEditId }) => {
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [categories, setCategories] = useState<ICategories[]>([]);
  const [templateData, setTemplateData] = useState<any>(null);

  const validateYamlFile = (value: any) => {
    const file = value[0];
    if (!file) {
      return false;
    }
    const fileExtension = file.name.split(".").pop();
    return fileExtension.toLowerCase() === "yaml";
  };

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        categoryId: Yup.string().required(""),
        provider: Yup.string().required(""),
        model: Yup.string().required(""),
        // version: Yup.string()
        //   .required("")
        //   .matches(/^0*[1-9]\d*$/),
        file: editId
          ? Yup.mixed().notRequired()
          : Yup.mixed()
              .test(
                "isYaml",
                "Invalid file type. Only .yaml files are allowed",
                validateYamlFile
              )
              .required("File is required"),
      })
    ),
    defaultValues: {
      type: "category",
      categoryId: "",
      provider: "",
      model: "",
      file: undefined,
      version: 0,
      isActive: true,
    },
  });

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
    trigger,
    setValue,
    watch,
    getValues,
  } = methods;

  useEffect(() => {
    getCategories().then((res: any) => {
      setCategories(res?.data.data);
    });
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);

  useEffect(() => {
    if (editId) {
      individualSystemPrompt(editId).then((res: any) => {
        // setSystemPrompt(res.data.data);
        setValue("type", res?.data?.data?.type);
        setValue("categoryId", res?.data?.data?.category);
        setValue("provider", res?.data?.data?.provider);
        setValue("model", res?.data?.data?.model);
        setValue("version", res?.data?.data?.version);
        setValue("isActive", res?.data?.data?.isActive);
        setTemplateData(res?.data?.data?.template);
      });
    }
  }, [editId, watch("provider")]);

  const formSubmitHandler = async (data: any) => {
    const formData = new FormData();
    formData.append("categoryId", data.categoryId);
    formData.append("type", data.type);
    formData.append("provider", data.provider);
    formData.append("model", data.model);
    formData.append("version", data.version);
    formData.append("isActive", data.isActive);
    formData.append("file", data.file[0]);

    if (editId) {
      try {
        await updateSystemPrompt(editId, formData);
        toast.success("Update Successfully!!");
        reset();
        setEditId("");
        setTemplateData(null);
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        await addSystemPrompt(formData);
        reset();
        setTemplateData(null);
        toast.success("Add Successfully!!");
      } catch (err) {
        console.error(err);
      }
    }
  };
  return (
    <>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(formSubmitHandler)}
      >
        <div className="max-50 space-y-6 ">
          <AutoCompleteCommon
            name="categoryId"
            error={errors.characterId}
            label="Select Category:"
            listData={categories?.map((item) => ({
              value: item._id,
              name: item.name,
            }))}
            value={getValues("categoryId")}
            placeholder="Select Character"
            selectedDropDownVal={(val: any) => {
              setValue("categoryId", val);
              trigger("categoryId");
            }}
          />
          <FormSelectField
            placeholder="Select Provider"
            label="Select Provider:"
            name="provider"
            option={providers?.map((item) => ({
              value: item.provider,
              name: item.provider,
            }))}
          />
          <AutoCompleteCommon
            disabled={getValues("provider") === ""}
            error={errors?.model}
            label="Model:"
            listData={providers.flatMap((item: any) =>
              item?.provider === getValues("provider")
                ? item?.models?.map((subModel: any) => ({
                    value: subModel,
                    name: subModel,
                  }))
                : []
            )}
            value={getValues("model")}
            // defaultValue={{ value: getValues("model") }}
            placeholder="Select Model"
            selectedDropDownVal={(val: any) => {
              setValue("model", val);
              trigger("model");
            }}
          />
          <div className="custom-form">
            <label htmlFor="file-upload">Description:</label>
            <input
              className={`w-full ${
                errors.file
                  ? "focus:border-error border-2 border-error"
                  : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5]"
              }`}
              id="file-upload"
              accept=".yaml"
              type="file"
              {...register("file")}
            />
          </div>
          {templateData && (
            <div className="custom-form items-center">
              <label>Template</label>
              <div className="flex p-[15px] border-t-2 shadow-sm border-l-2 rounded-[10px] h-[200px] w-full overflow-y-auto">
                <pre>{JSON.stringify(templateData, null, 2)}</pre>
              </div>
            </div>
          )}

          {/* <FormTextAreaField
            name="systemPrompt"
            label="Description:"
            placeholder="Description"
            className="h-[150px]"
          /> */}
          <FormTextField
            readOnly
            type="number"
            name="version"
            label="Version No:"
            placeholder="Number"
          />
          <FormCheckBoxField label="isActive:" name="isActive" />
          <button type="submit" className="btn-black mt-5">
            {editId ? "Update" : "Add"}
          </button>
        </div>
      </FormProvider>
      <ToastContainer />
    </>
  );
};

export default AddSystemPromptCategories;
