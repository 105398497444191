import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import {
  createBoardRoom,
  individualBoardRoom,
  updateBoardRoom,
} from "../../services/boardroom.services";
import "react-toastify/dist/ReactToastify.css";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import { useForm } from "react-hook-form";
import FormTextField from "../../common/hook-form/FormTextField";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import ErrorMessage from "../../common/ErrorMessage";
/**
 * The Addsuggestedboardrooms component represents a form for adding suggested boardrooms.
 * It allows the user to enter a boardroom name and select a character.
 * The user can add multiple suggested boardrooms.
 * Each suggested boardroom is displayed with its name, media, and description.
 * The user can edit or delete each suggested boardroom.
 * The user can also add a new boardroom.
 */

interface IProps {
  allCharacter: IAICharacters[];
  editId: string | null;
  setEditId: (id: string | null) => void;
}

const Addsuggestedboardrooms: React.FC<IProps> = ({
  allCharacter,
  editId,
  setEditId,
}) => {
  const [show, setShow] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [boardRooms, setBoardRooms] = useState<IAICharacters[]>([]);
  const [characterId, setCharacterId] = useState<string | null>(null);
  // react hook form

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        boardName: Yup.string().required(""),
        theme: Yup.string().required(""),
        // characterId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      boardName: "",
      theme: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
  } = methods;

  // react hook form

  const addHandler = () => {
    if (characterId) {
      if (boardRooms.length > 0 && (boardRooms as any)[0] !== undefined) {
        const selected: any = boardRooms.find(
          (boardRooms: IAICharacters) => boardRooms._id === characterId
        );
        if (selected === undefined) {
          const characterFind = allCharacter.find(
            (Character: IAICharacters) => Character._id === characterId
          );
          if (characterFind) {
            setBoardRooms([...boardRooms, characterFind]);
            setCharacterId("")
          }
        }
      } else {
        const selected = allCharacter.find(
          (Character: IAICharacters) => Character._id === characterId
        );
        if (selected) {
          setBoardRooms([...boardRooms, selected]);
        }
      }
    }
  };
  // useEffect(() => {
  //   if (editId) {
  //     individualBoardRoom(editId).then((res: any) => {
  //       setBoardRooms(res.data.data);
  //     }).
  //       catch((err) => {
  //         console.log(err)
  //       })
  //   }
  // }, [editId]);
  const onDelete = (index: string) => {
    setRemoveIndex(index);
    handleShow();
  };
  const onRemoveHandler = () => {
    const filterBoard = boardRooms.filter(
      (boardRoom) => boardRoom._id !== removeIndex
    );
    setBoardRooms(filterBoard);
    handleClose();
  };
  const addBoardRoomHandler = (formData: any) => {
    if (formData?.boardName !== "" && boardRooms.length > 0) {
      if (editId) {
        const characters = boardRooms.map((obj) => obj._id);
        updateBoardRoom(editId, {
          boardroomName: formData?.boardName,
          characterIds: characters,
          theme: formData?.theme,
        })
          .then(() => {
            toast.success("Updated Successfully!");
            setBoardRooms([]);
            // setBoardName("");
            reset();
            setEditId("");
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      } else {
        const characters = boardRooms.map((obj) => obj._id);
        createBoardRoom({
          boardroomName: formData?.boardName,
          characterIds: characters,
          theme: formData?.theme,
        })
          .then(() => {
            toast.success("Added Successfully!");
            setBoardRooms([]);
            // setBoardName("");
            reset();
          })
          .catch((err) => {
            toast.error(err.response.data.message);
          });
      }
    } else {
      setErrorMessage("At least  One Character is Required");
    }
  };

  useEffect(() => {
    if (editId) {
      individualBoardRoom(editId)
        .then((res: any) => {
          setValue("boardName", res?.data?.data.boardroomName);
          setValue("theme", res?.data?.data?.theme);
          setBoardRooms(res?.data?.data?.boardroomCharacters);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [editId]);
  return (
    <>
      <ToastContainer />
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(addBoardRoomHandler)}
      >
        <div className="max-50 space-y-6">
          <FormTextField
            name="boardName"
            placeholder="Enter Boardroom Name"
            label="Suggested Boardroom Name:"
          />
          <FormTextField
            name="theme"
            placeholder="Enter Theme Name"
            label="Theme Name:"
          />
          <div className=" relative">
            <AutoCompleteCommon
              // defaultValue={{ value: characterId }}
              // error={errors?.categoryId}
              label="Choose Character:"
              listData={allCharacter?.map((item) => ({
                name: item?.name,
                value: item?._id,
              }))}
              value={characterId}
              // value={{ value: characterId }}
              placeholder="Choose a Character"
              selectedDropDownVal={(val: any) => setCharacterId(val)}
            />
            <div className=" absolute -right-20 top-0 translate-x-1/2  pt-2">
              <button
                type="button"
                className=" border border-black w-[100px] text-black h-[45px] text-f18 rounded-[10px] font-medium"
                onClick={addHandler}
              >
                Add
              </button>
            </div>
          </div>
          <div className="flex items-center justify-between ">
            <h3 className="addCharaterinfo font-medium  text-[18px] ">
              Character Added : <span>{boardRooms.length}</span>
            </h3>
          </div>
          {boardRooms?.length > 0 &&
            boardRooms?.map((boardroom: IAICharacters) => (
              <div className="Boardroom-box" key={boardroom._id}>
                <div className=" flex justify-between h-fit">
                  <div className="Boardroom-Media">
                    <span className=" w-[80px] h-[80px] object-cover">
                      <img alt="" src={boardroom.logo} />
                    </span>
                    <div>
                      <h6>{boardroom.name}</h6>
                      <ol>
                        {boardroom?.tags.length > 0 &&
                          boardroom?.tags.map((tag: any) => <li>{tag}</li>)}
                      </ol>
                    </div>
                  </div>
                  <Link onClick={() => onDelete(boardroom._id)} to="#">
                    <Icon icon="material-symbols:delete-outline" />
                  </Link>
                </div>

                <p>{boardroom?.description}</p>
              </div>
            ))}
          <ErrorMessage
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
          />
          <button type="submit" className="btn-black mt-5">
            {editId ? "Update Boardroom" : "Add Boardroom"}
          </button>
        </div>
      </FormProvider>

      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onRemoveHandler}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Addsuggestedboardrooms;
