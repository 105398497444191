import AddPerkType from "../../component/AddPerkType/AddPerkType";
import ViewPerkType from "../../component/ViewPerkType/ViewPerkType";
import { useState, useEffect } from "react";
import { getPerks } from "../../services/perk.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
interface Perk {
  // Define the properties of a perk
  id: string | undefined | number;
  title: string;
  description: string;
  value: number | string;
  type?: string;
}

const PerkType: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [allPerk, setAllPerk] = useState<Perk[]>([]);
  const [editId, setEditId] = useState("");

  /**
   * Fetches data from local storage and updates the state with the fetched data.
   */

  /**
   * Event handler for tab change.
   * @param tab - The selected tab.
   */
  const handleTabChange = (tab: string) => {
    setAllPerk([]);
    setActiveTab(tab);
  };
  const getAllPerks = () => {
    getPerks()
      .then((perk: any) => {
        setAllPerk(perk?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Initial data fetch when component mounts
  useEffect(() => {
    getAllPerks();
  }, [activeTab]);
  return (
    <div className="Homepagecontent">
      <Tabs
        onValueChange={(val) => {
          setActiveTab(val);
          setEditId("");
        }}
        value={activeTab}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add Perk Type</TabsTrigger>
          <TabsTrigger value="second">View all Perk Type</TabsTrigger>
        </TabsList>
        <TabsContent value="first">
          <AddPerkType
            allPerk={allPerk}
            editId={editId}
            setEditId={setEditId}
          />{" "}
        </TabsContent>
        <TabsContent value="second">
          <ViewPerkType
            allPerk={allPerk}
            handleTabChange={handleTabChange}
            getAllPerks={getAllPerks}
            setEditId={setEditId}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default PerkType;
