import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import { ISystemPrompt } from "../../api/interfaces/systemPrompt";
import { getAllCharacter } from "../../services/character.services";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormSelectField from "../../common/hook-form/FormSelectField";
import { getProviderModel } from "../../services/provider-model.services";
import {
  addSystemPrompt,
  individualSystemPrompt,
  updateSystemPrompt,
} from "../../services/systemPrompt";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import FormCheckBoxField from "../../common/hook-form/FormCheckBox";

interface IProps {
  editId: string | null;
  setEditId: (id: string | null) => void;
}
interface ProviderModel {
  id?: string;
  models: string[];
  provider: string;
}

const AddSystemPromptCharacters: React.FC<IProps> = ({ editId, setEditId }) => {
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [character, setCharacters] = useState<IAICharacters[]>([]);
  const [templateData, setTemplateData] = useState<any>(null);
  const validateYamlFile = (value: any) => {
    const file = value[0];
    if (!file) {
      return false;
    }
    const fileExtension = file.name.split(".").pop();
    return fileExtension.toLowerCase() === "yaml";
  };

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        characterId: Yup.string().required(""),
        provider: Yup.string().required(""),
        model: Yup.string().required(""),
        // version: Yup.string()
        //   .required("")
        //   .matches(/^0*[1-9]\d*$/),
        file: editId
          ? Yup.mixed().notRequired()
          : Yup.mixed()
              .test(
                "isYaml",
                "Invalid file type. Only .yaml files are allowed",
                validateYamlFile
              )
              .required("File is required"),
      })
    ),
    defaultValues: {
      type: "character",
      characterId: "",
      provider: "",
      model: "",
      file: undefined,
      version: 0,
      isActive: true,
    },
  });
  const {
    handleSubmit,
    reset,
    formState: { errors },
    trigger,
    setValue,
    watch,
    getValues,
    register,
  } = methods;
  useEffect(() => {
    getAllCharacter().then((res: any) => {
      setCharacters(res?.data.data);
    });
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);
  useEffect(() => {
    if (editId) {
      individualSystemPrompt(editId).then((res: any) => {
        setValue("type", res?.data?.data?.type);
        setValue("characterId", res?.data?.data?.character);
        setValue("provider", res?.data?.data?.provider);
        setValue("model", res?.data?.data?.model);
        setValue("version", res?.data?.data?.version);
        setValue("isActive", res?.data?.data?.isActive);
        setTemplateData(res?.data?.data?.template);
      });
    }
  }, [editId, watch("provider")]);

  const formSubmitHandler = (data: any) => {
    const formData = new FormData();
    formData.append("characterId", data.characterId);
    formData.append("type", data.type);
    formData.append("provider", data.provider);
    formData.append("model", data.model);
    formData.append("version", data.version);
    formData.append("isActive", data.isActive);
    formData.append("file", data.file[0]);
    if (editId) {
      updateSystemPrompt(editId, formData).then(() => {
        toast.success("Update Sucessfully!!");
        reset();
        setEditId("");
        setTemplateData(null);
      });
    } else {
      addSystemPrompt(formData).then(() => {
        toast.success("Add Sucessfully!!");
        reset();
        setTemplateData(null);
      });
    }
  };

  return (
    <>
      <FormProvider
        methods={methods}
        onSubmit={handleSubmit(formSubmitHandler)}
      >
        <div className="max-50 space-y-6 ">
          <AutoCompleteCommon
            name="characterId"
            error={errors.characterId}
            label="Select Character:"
            listData={character?.map((item) => ({
              value: item._id,
              name: item.name,
            }))}
            value={getValues("characterId")}
            // defaultValue={{ value: getValues("characterId") }}
            placeholder="Select Character"
            selectedDropDownVal={(val: any) => {
              setValue("characterId", val);
              trigger("characterId");
            }}
          />
          <FormSelectField
            placeholder="Select Provider"
            label="Select Provider:"
            name="provider"
            option={providers?.map((item) => ({
              value: item.provider,
              name: item.provider,
            }))}
          />
          <AutoCompleteCommon
            disabled={getValues("provider") === ""}
            error={errors?.model}
            label="Model:"
            listData={providers.flatMap((item: any) =>
              item?.provider === getValues("provider")
                ? item?.models?.map((subModel: any) => ({
                    value: subModel,
                    name: subModel,
                  }))
                : []
            )}
            value={getValues("model")}
            // defaultValue={{ value: getValues("model") }}
            placeholder="Select Model"
            selectedDropDownVal={(val: any) => {
              setValue("model", val);
              trigger("model");
            }}
          />
          <div className="custom-form">
            <label htmlFor="file-upload">Description:</label>
            <input
              className={`w-full ${
                errors.file
                  ? "focus:border-error border-2 border-error"
                  : " border-1 border-l-[#f6f4f5] border-t-[#f6f4f5]"
              }`}
              id="file-upload"
              accept=".yaml"
              type="file"
              {...register("file")}
            />
          </div>
          {templateData && (
            <div className="custom-form items-center">
              <label>Template</label>
              <div className="flex p-[15px] border-t-2 shadow-sm border-l-2 rounded-[10px] h-[200px] w-full overflow-y-auto">
                <pre>{JSON.stringify(templateData, null, 2)}</pre>
              </div>
            </div>
          )}
          {/* <FormTextAreaField
            name="systemPrompt"
            label="Description:"
            placeholder="Description"
            className="h-[150px]"
          /> */}
          <FormTextField
            type="number"
            name="version"
            label="Version No:"
            placeholder="Number"
            readOnly
          />
          <FormCheckBoxField label="isActive:" name="isActive" />
          <button type="submit" className="btn-black mt-5">
            {editId ? "Update" : "Add"}
          </button>
        </div>
      </FormProvider>
      <ToastContainer />
    </>
  );
};

export default AddSystemPromptCharacters;
