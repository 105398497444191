import { useEffect, useMemo, useState } from "react";

import AddPerk from "../../component/AddPerk/AddPerk";
import ViewallPerk from "../../component/ViewallPerk/ViewallPerk";
import { getPerks } from "../../services/perk.services";
import { getUsers } from "../../services/user.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
interface Perk {
  // Define the properties of a perk
  id: string | undefined | number;
  title: string;
  description: string;
  value: number | string;
  type?: string;
}

const Perkpage: React.FC = () => {
  const [activeTab, setActiveTab] = useState("first");
  const [users, setUsers] = useState([]);
  const [perks, setPerks] = useState<Perk[]>([]);

  function getAllPerks() {
    getPerks().then((res: any) => {
      setPerks(res?.data.data);
    });
  }
  const handleTab = (key: string) => {
    setActiveTab(key);
  };

  useEffect(() => {
    getAllPerks();
    getUsers().then((res: any) => {
      setUsers(res?.data.data);
    });
  }, [activeTab]);

  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="first" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add a Perk</TabsTrigger>
          <TabsTrigger value="second">View all Perk</TabsTrigger>
        </TabsList>
        <TabsContent value="first" className="p-[30px]">
          <AddPerk perks={perks} users={users} />
        </TabsContent>
        <TabsContent value="second" className="p-[30px]">
          <ViewallPerk perks={users} setActiveTab={setActiveTab} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Perkpage;
