import Keycloak, { KeycloakLoginOptions } from "keycloak-js";
import React, { useEffect } from "react";

const initOptions = {
  url: process.env.REACT_APP_KEYCLOAK_URL!,
  realm: process.env.REACT_APP_KEYCLOAK_REALM!,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID!,
  onLoad: "check-sso", // check-sso | login-required
  KeycloakResponseType: "code",
};
const kc = new Keycloak(initOptions);
kc.init({
  onLoad: "check-sso",
  silentCheckSsoRedirectUri: window.location.origin + "/silent-check-sso.html",
  checkLoginIframe: false,
  pkceMethod: "S256",
}).then((auth) => {
  if (!auth) window.location.reload();
});

interface RequireAuthProps {
  children: React.ReactElement;
}

interface ExtendedKeycloakLoginOptions extends KeycloakLoginOptions {
  codeChallengeMethod?: string;
  codeChallenge?: string;
}

async function generatePKCE() {
  // Generate a random string as the code verifier
  const array = new Uint32Array(32);
  window.crypto.getRandomValues(array);
  const codeVerifier = Array.from(array)
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("")
    .substr(0, 128); // Making sure the length does not exceed 128 characters

  // Generate the code challenge
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digest = await window.crypto.subtle.digest("SHA-256", data);

  const base64String = btoa(
    String.fromCharCode.apply(
      null,
      new Uint8Array(digest) as unknown as number[]
    )
  );

  const codeChallenge = base64String
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=/g, "");

  return { codeVerifier, codeChallenge };
}

export async function getToken() {
  return await kc
    .updateToken(5)
    .then(() => kc.token)
    .catch(() => null);
}

export async function kcLogout() {
  return await kc.logout();
}

export async function getUserData() {
  return await kc.loadUserProfile();
}
export function isAuthenticated() {
  return kc.authenticated ? true : false;
}
export function GoToLogin() {
  generatePKCE()
    .then(({ codeVerifier, codeChallenge }) => {
      kc.login({
        scope: "openid",
        codeChallengeMethod: "S256",
        codeChallenge,
        redirectUri: window.location.origin,
      } as ExtendedKeycloakLoginOptions); // Changed the type assertion here
    })
    .catch((error) => {
      console.error("Error generating PKCE:", error);
    });
}
const RequireAuth: React.FC<RequireAuthProps> = ({ children }) => {
  useEffect(() => {
    if (kc.authenticated) return;
    generatePKCE()
      .then(({ codeVerifier, codeChallenge }) => {
        kc.login({
          scope: "openid",
          codeChallengeMethod: "S256",
          codeChallenge,
          redirectUri: window.location.origin,
        } as ExtendedKeycloakLoginOptions); // Changed the type assertion here
      })
      .catch((error) => {
        console.error("Error generating PKCE:", error);
      });
    kc.updateToken(5)
      .then(() => kc.token)
      .catch(() => null);
  }, []);
  return kc.authenticated ? children : null;
};

export default RequireAuth;
