import { useEffect, useMemo, useState } from "react";
import { ICategories } from "../../api/interfaces/categories";
import AddCategory from "../../component/AddCategory/AddCategory";
import ViewCategory from "../../component/ViewCategory/ViewCategory";
import { getCategories } from "../../services/categories.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";

const Categories: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("addCategory");
  const [editCategoryData, setEditCategoryData] = useState<ICategories | null>(
    null
  );
  const [allcategory, setAllcategory] = useState<ICategories[]>([]);
  const [isNew, setIsNew] = useState<boolean>(false);

  /**
   * Handles the tab change event and updates the active tab state.
   * Also fetches data from localStorage when the tab changes.
   * @param tab - The tab key of the clicked tab
   */

  // Initial data fetch when component mounts
  useEffect(() => {
    getCategories()
      .then((categories: any) => {
        setAllcategory(categories?.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      setIsNew(false);
      localStorage.removeItem("addcategory");
    };
  }, []);

  return (
    <div className="Homepagecontent ">
      <Tabs
        value={activeTab}
        defaultValue="addCategory"
        onValueChange={(val) => {
          setEditCategoryData(null);
          setActiveTab(val);
        }}
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="addCategory">Add a category</TabsTrigger>
          <TabsTrigger value="allCategory">View all categories</TabsTrigger>
        </TabsList>
        <TabsContent value="addCategory">
          <AddCategory isNew={isNew} editCategory={editCategoryData} />
        </TabsContent>
        <TabsContent value="allCategory">
          <ViewCategory
            setEditCategoryData={setEditCategoryData}
            handleTabChange={setActiveTab}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Categories;
