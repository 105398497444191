import { ChangeEventHandler, useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { ICategories } from "../../api/interfaces/categories";
import { ICategoryQuestionPool } from "../../api/interfaces/questionPools";
import {
  deleteCategoryQuestionsPool,
  getAllCategoryQuestionsPool,
  getCategories,
  getCategoryQuestionsByLimit,
} from "../../services/categories.services";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "../../common/CommonDialog";
import { toast, ToastContainer } from "react-toastify";
import AutoCompleteCommon from "../../common/CommonAutoComplete";

interface Props {
  setActiveTab: (tab: string) => void;
  activeTab: string;
  setEditId: (id: string) => void;
}

/**
 * The ViewAllQuestion component displays a list of questions.
 * The user can search for questions, select a category, edit or delete each question,
 * and navigate through pagination.
 */
const ViewAllQuestion: React.FC<Props> = ({
  setActiveTab,
  activeTab,
  setEditId,
}) => {
  const [show, setShow] = useState(false);
  const [questionPools, setQuestionPools] = useState<ICategoryQuestionPool[]>(
    []
  );
  const [category, setCategory] = useState<ICategories[]>([]);
  const [poolsByCategory, setPoolsByCategory] = useState<
    ICategoryQuestionPool[]
  >([]);
  const [pickedIndex, setPickedIndex] = useState<string | null>(null);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<any>("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500);
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [inputValue]);

  const handleInputChange = (event: any) => {
    setInputValue(event.target.value);
  };

  const handleClose = () => {
    setPickedIndex(null);
    setShow(false);
  };
  const handleShow = (_id: string | null) => {
    if (_id) setPickedIndex(_id);
    setShow(true);
  };
  const onDelete = () => {
    if (pickedIndex) {
      deleteCategoryQuestionsPool(pickedIndex).then(() => {
        const updatedPools = poolsByCategory.filter(
          (f) => f._id !== pickedIndex
        );
        toast.success("Deleted Successfully!!");
        setPoolsByCategory(updatedPools);
        setQuestionPools(updatedPools);
        handleClose();
      });
    }
  };

  const categorySelected = (val: any) => {
    setSelectedCategory(val);
  };

  useEffect(() => {
    getCategories().then((res: any) => {
      setCategory(res?.data.data);
    });
  }, [activeTab]);
  useEffect(() => {
    getCategoryQuestionsByLimit(page, selectedCategory, debouncedValue).then(
      (res: any) => {
        setPoolsByCategory(res?.data.data);
        setQuestionPools(res?.data.data);
        setTotal(res?.data.total);
      }
    );
    setEditId("");
  }, [page, debouncedValue, selectedCategory]);
  const onEdit = (id: string) => {
    setActiveTab("first");
    setEditId(id);
  };
  let items: any = [];
  let TotalLength = total;
  const PagePagination = () => {
    items = [];
    const totalPages = Math.ceil(total / 5);
    const maxPagesToShow = 5;
    let startPage = 1;
    let endPage = Math.min(maxPagesToShow, totalPages);

    if (page > Math.floor(maxPagesToShow / 2)) {
      startPage = Math.max(1, page - Math.floor(maxPagesToShow / 2));
      endPage = Math.min(totalPages, startPage + maxPagesToShow - 1);
    }

    for (let number = startPage; number <= endPage; number++) {
      items.push(
        <button
          key={number}
          onClick={() => setPage(number)}
          className={`pagination-btn ${
            number === page
              ? "border-blueColor text-blueColor pagination-btn-active "
              : "border-anthengreyColor"
          } border-1`}
        >
          {number}
        </button>
      );
    }
    return items;
  };

  const previous = () => {
    if (page !== 1) {
      setPage((prev) => prev - 1);
    }
  };
  const next = () => {
    if (page !== TotalLength.length - 1) {
      setPage((prev) => prev + 1);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="max-50 view-content CategoryQuestion">
        <div className="flex justify-between items-center space-x-8">
          <AutoCompleteCommon
            className="w-full"
            listData={category?.map((item) => ({
              name: item?.name,
              value: item?._id,
            }))}
            value={selectedCategory}
            placeholder="Choose a Category"
            selectedDropDownVal={categorySelected}
          />
          <div className="custom-search w-full">
            <input
              type="text"
              placeholder="Search"
              onChange={handleInputChange}
              value={inputValue}
            />
            <Link to="#">
              <img src="./assets/image/search.svg" alt="img-id-2" />
            </Link>
          </div>
        </div>
        {poolsByCategory.length > 0 &&
          poolsByCategory.map((f) => {
            return (
              <div>
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div className="flex justify-between items-center w-full py-1">
                    <h5 className=" text-start">
                      {f?.name === "" ? "Not Specified" : f?.name}
                    </h5>
                    <h5 className="text-end">
                      {category.length > 0 &&
                        f.categoryId &&
                        category.map(
                          (item) =>
                            item._id === f.categoryId && `(${item.name})`
                        )}
                    </h5>
                  </div>
                </div>
                <div className="flex space-x-2 py-2 justify-end">
                  <Link to="#" onClick={() => onEdit(f._id)}>
                    <Icon icon="bx:edit" />
                  </Link>
                  <Link onClick={() => handleShow(f._id)} to="#">
                    <Icon icon="material-symbols:delete-outline" />
                  </Link>
                </div>
                <div className="questionaddwrap">
                  {f.questions.map((que, index) => (
                    <div className="questionbox" key={index}>
                      <p>{que}</p>
                      {/* <span>
                        <Link to="#" onClick={() => onEdit(f._id)}>
                          <Icon icon="bx:edit" />
                        </Link>
                        <Link onClick={() => handleShow(f?._id)} to="#">
                          <Icon icon="material-symbols:delete-outline" />
                        </Link>
                      </span> */}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        <div className="pagination">
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200 border-1 disabled:border-0 pagination-btn border-gray-200"
            disabled={page === 1}
            onClick={() => previous()}
          >
            {"<"}
          </button>
          <>{PagePagination()}</>
          <button
            className=" disabled:bg-[#919eab] text-[#C4CDD5] hover:border-gray-200  disabled:border-0 border-1 pagination-btn border-gray-200"
            disabled={page === TotalLength}
            onClick={() => next()}
          >
            {">"}
          </button>
        </div>
      </div>
      <Dialog open={show} onOpenChange={setShow}>
        <DialogContent className="sm:max-w-[370px] py-[40px] bg-white delete-modal">
          <DialogHeader>
            <p>Are you sure?</p>
          </DialogHeader>
          <DialogFooter className="modal-footer ">
            <button className="btn-black-border btn" onClick={handleClose}>
              Cancel
            </button>
            <button className="btn-black" onClick={onDelete}>
              Delete
            </button>{" "}
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewAllQuestion;
