import http from "../http/request";

function getTags() {
    return new Promise((resolve, reject) => {
      http(`/ai-character/tags/list`, "get", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
}

function createTags(body:any) {
    return new Promise((resolve, reject) => {
      http(`/ai-character/tags/add`, "put", body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
}

function deleteTags(body:any) {
    return new Promise((resolve, reject) => {
      http(`/ai-character/tags/remove`, "put", body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
}
function updateTags(body:any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character/tags/rename`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {getTags,createTags,deleteTags,updateTags}