import http from "../http/request";

function getUsers(page?: any, account_type?: string) {
  return new Promise((resolve, reject) => {
    http(
      `/users/all?limit=999999${
        page && account_type ? `&page=${page}&account_type=${account_type}` : ""
      }`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getUsersItems(page?: any, userId?: string) {
  return new Promise((resolve, reject) => {
    http(
      `/users/items?limit=100&order=asc${
        page && userId ? `&page=${page}&userId=${userId}` : ""
      }`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateUserType(userId: string, account_type: string) {
  return new Promise((resolve, reject) => {
    http(`/update-user/${userId}`, "put", {
      account_type,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export { getUsers, updateUserType,getUsersItems };
