import http from "../http/request";

function createBoardRoom(body: any) {
  return new Promise((resolve, reject) =>
    http(`/boardroom-pools`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  );
}

function getAllBoardRoom() {
  return new Promise((resolve, reject) =>
    http(`/boardroom-pools`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  );
}

function getBoardRoomByLimit(page?: number, search?: any, limit?: any) {
  return new Promise((resolve, reject) =>
    http(
      `/boardroom-pools?${page ? `page=${page}` : ""}${
        limit ? `&limit=${limit}` : ""
      }${search ? `&search=${search}` : ""}`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  );
}
function getSuggestedQuestionsByLimit(
  page?: number | null,
  boardroomPoolId?: any,
  search?: any
) {
  return new Promise((resolve, reject) => {
    http(
      `/boardrooms-questions-pool/all?${page ? `page=${page}` : ""}&limit=5${
        boardroomPoolId && `&boardroomPoolId=${boardroomPoolId}`
      }${search ? `&search=${search}` : ""}`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getAllSuggestedQuestionsPool() {
  return new Promise((resolve, reject) => {
    http(`/boardrooms-questions-pool/all`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function addLinkQuestiontoPool(boardroomPoolId: string, poolId: string) {
  return new Promise((resolve, reject) => {
    http(
      `/boardrooms-questions-pool/link-pool-to-boardroom?boardroomPoolId=${boardroomPoolId}&poolId=${poolId}`,
      "post",
      {}
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function individualBoardRoom(id: string) {
  return new Promise((resolve, reject) => {
    http(`/boardroom-pools/${id}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateSuggestedQuestionsPool(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/boardrooms-questions-pool/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function createSuggestedQuestionsPool(body: any) {
  return new Promise((resolve, reject) => {
    http(`/boardrooms-questions-pool`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteSuggestedQuestionsPool(id: string) {
  return new Promise((resolve, reject) => {
    http(`/boardrooms-questions-pool/${id}`, "delete", {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function updateBoardRoom(id: string, body: any) {
  return new Promise((resolve, reject) =>
    http(`/boardroom-pools/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  );
}
function deleteBoardRoom(id: string) {
  return new Promise((resolve, reject) =>
    http(`/boardroom-pools/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      })
  );
}

export {
  createBoardRoom,
  getAllBoardRoom,
  deleteBoardRoom,
  getBoardRoomByLimit,
  updateSuggestedQuestionsPool,
  createSuggestedQuestionsPool,
  getSuggestedQuestionsByLimit,
  deleteSuggestedQuestionsPool,
  getAllSuggestedQuestionsPool,
  addLinkQuestiontoPool,
  updateBoardRoom,
  individualBoardRoom,
};
