import http from "../http/request";

function getFeedbackNotAnswerByLimit(page: number, role?:string, type?:string,userId?:string ) {
    return new Promise((resolve, reject) => {
      http(`/feedback/notAnswered?page=${page}&limit=5${role&&`&role=${role}`}${type&&`&type=${type}`}${userId&&`&userId=${userId}`}`, "get", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function getFeedbackAllAnswerByLimit(page: number, role?:string, type?:string,userId?:string ) {
    return new Promise((resolve, reject) => {
      http(`/feedback/all?page=${page}&limit=5${role&&`&role=${role}`}${type&&`&type=${type}`}${userId&&`&userId=${userId}`}`, "get", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function updateIndividualFeedback(userId:string,answer: any ) {
    return new Promise((resolve, reject) => {
      http(`/feedback/${userId}`, "put", answer)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function getFeedbackFilter() {
    return new Promise((resolve, reject) => {
      http(`/feedback/filter-parameters`, "get", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  export {getFeedbackNotAnswerByLimit,getFeedbackFilter,getFeedbackAllAnswerByLimit, updateIndividualFeedback}