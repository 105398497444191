import { useEffect, useMemo, useState } from "react";
import AddCharacter from "../../component/AddCharacter/AddCharacter";
import ViewCharacter from "../../component/ViewCharacter/ViewCharacter";
import { IAICharacters } from "../../api/interfaces/aiCharacters";
import { getAllCharacter } from "../../services/character.services";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";

// eslint-disable-next-line @typescript-eslint/no-redeclare
const Character: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>("first");
  const [isNew, setIsNew] = useState<boolean>(false);
  const [editCharacter, setEditCharacter] = useState<IAICharacters | null>(
    null
  );

  /**
   * Fetches data from localStorage and updates the state with the fetched data.
   */

  /**
   * Handles the tab change event and updates the active tab state.
   * Also fetches data from localStorage when the tab changes.
   * @param tab - The tab key of the clicked tab
   */
  const handleTabChange = (tab: string, isNew: boolean = false) => {
    if (isNew === true) {
      setEditCharacter(null);
      setIsNew(true);
    } else {
      setIsNew(false);
    }
    setActiveTab(tab);
  };
  return (
    <div className="Homepagecontent">
      <Tabs
        value={activeTab}
        onValueChange={(val) => {
          setEditCharacter(null);
          setActiveTab(val);
        }}
        defaultValue="first"
        className="w-full"
      >
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="first">Add an AI character</TabsTrigger>
          <TabsTrigger value="second">View AI characters</TabsTrigger>
        </TabsList>
        <TabsContent value="first">
          <AddCharacter isNew={isNew} editCharacter={editCharacter} />{" "}
        </TabsContent>
        <TabsContent value="second">
          <ViewCharacter
            handleTabChange={handleTabChange}
            setEditCharacter={setEditCharacter}
          />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Character;
