import http from "../http/request";

function getAllProducts(type: string, page: number, platform: any) {
  return new Promise((resolve, reject) => {
    http(
      `/products/all?page=${page}&limit=5&eventType=${type}${
        platform ? `&type=${platform}` : ""
      }`,
      "get",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getProductById(id: string) {
  return new Promise((resolve, reject) => {
    http(`/products/one/${id}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateProductById(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/products/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getAllGlobalProductByProductId(productId: any) {
  return new Promise((resolve, reject) => {
    http(`/credits-config/all?productId=${productId}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getAllGlobalProduct() {
  return new Promise((resolve, reject) => {
    http(`/credits-config/all`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function addGlobalProduct(body: any) {
  return new Promise((resolve, reject) => {
    http(`/credits-config`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function deleteGlobalProduct(id: any) {
  return new Promise((resolve, reject) => {
    http(`/credits-config/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateGlobalProduct(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/credits-config/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  getAllProducts,
  getAllGlobalProductByProductId,
  getProductById,
  updateProductById,
  addGlobalProduct,
  deleteGlobalProduct,
  updateGlobalProduct,
  getAllGlobalProduct,
};
