import http from "../http/request";

function addCharacter(body: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getAllCharacter() {
  return new Promise((resolve, reject) => {
    http(`/ai-character/all`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getCharactersBylimit(page: number,search: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character/all?page=${page}&limit=5${search?`&search=${search}`:''}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function deleteCharacter(id: string) {
  return new Promise((resolve, reject) => {
    http(`/ai-character/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateCharacterById(id: string | undefined, body: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function createCharacterQuestionPool(body: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character-suggestions`, "post", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
function getAllCharacterQuestionsPool() {
  return new Promise((resolve, reject) => {
    http(`/ai-character-suggestions/all`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function getCharacterQuestionsByLimit(page: number, characterId?:any, search?: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character-suggestions/all?page=${page}&limit=5${characterId&&`&characterId=${characterId}`}${search&&`&search=${search}`}`, "get", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}


function deleteCharacterQuestionsPool(id: string) {
  return new Promise((resolve, reject) => {
    http(`/ai-character-suggestions/${id}`, "delete", null)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function updateCharacterQuestionsPool(id: string, body: any) {
  return new Promise((resolve, reject) => {
    http(`/ai-character-suggestions/${id}`, "put", body)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

function addLinkCharactertoPool(characterId: string, poolId: string) {
  return new Promise((resolve, reject) => {
    http(
      `/ai-character-suggestions/pool/replace-character-pool?characterId=${characterId}&questionPoolId=${poolId}`,
      "put",
      null
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

export {
  addCharacter,
  getAllCharacter,
  getCharactersBylimit,
  deleteCharacter,
  updateCharacterById,
  createCharacterQuestionPool,
  getAllCharacterQuestionsPool,
  deleteCharacterQuestionsPool,
  updateCharacterQuestionsPool,
  getCharacterQuestionsByLimit,
  addLinkCharactertoPool,
};
