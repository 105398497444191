import http from "../http/request";

function addProviderModel(body: any) {
    return new Promise((resolve, reject) => {
      http(`/products/providers-list`, "post", body)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function getProviderModel() {
    return new Promise((resolve, reject) => {
      http(`/products/providers-list`, "get", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  function deleteProvider(id:string) {
    return new Promise((resolve, reject) => {
      http(`/products/providers-list/${id}`, "delete", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  function deleteProviderModel(id:string,model:string) {
    return new Promise((resolve, reject) => {
      http(`/products/providers-list/${id}/${model}`, "delete", null)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  export {addProviderModel,getProviderModel,deleteProvider,deleteProviderModel}