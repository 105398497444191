import { useEffect, useMemo, useState } from "react";
import AddCategory from "../../component/AddCategory/AddCategory";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import FormTextField from "../../common/hook-form/FormTextField";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import { getUsers } from "../../services/user.services";
import * as Yup from "yup";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common/CommonTab";
import { useForm } from "react-hook-form";
import AutoCompleteCommon from "../../common/CommonAutoComplete";
import {
  addCreditItemToUser,
  getAllPerkByUser,
} from "../../services/perk.services";
import { toast, ToastContainer } from "react-toastify";
import FormSelectField from "../../common/hook-form/FormSelectField";
import { getProviderModel } from "../../services/provider-model.services";
import ViewAllUserPacks from "../../component/ViewAllUserPacks/ViewAllUserPacks";
interface ProviderModel {
  id?: string;
  model: string;
  provider: string;
}
const CreditsToUser: React.FC = () => {
  const [users, setUsers] = useState([]);
  const [providers, setProviders] = useState<ProviderModel[]>([]);
  const [totalCredit, setTotalCredits] = useState<string | null>(null);
  const [getAllPerk, setAllPerk] = useState<null | any[]>(null);

  useEffect(() => {
    getUsers().then((res: any) => {
      setUsers(res?.data.data);
    });
  }, []);
  useEffect(() => {
    getProviderModel().then((res: any) => {
      setProviders(res?.data.data);
    });
  }, []);
  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        credits: Yup.string()
          .required("")
          .matches(/^0*[1-9]\d*$/),
        userId: Yup.string().required(""),
        provider: Yup.string().required(""),
        model: Yup.string().required(""),
      })
    ),
    defaultValues: {
      credits: "",
      provider: "",
      model: "",
      userId: "",
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = methods;
  const handleAddCredits = (formData: any) => {
    const payload = {
      ...formData,
      credits: Number(formData?.credits),
    };
    addCreditItemToUser(payload)
      .then((res) => {
        toast.success("Added Successfully");
        reset();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
  return (
    <div className="Homepagecontent">
      <Tabs defaultValue="addCategory" className="w-full">
        <TabsList className="grid w-full grid-cols-2 gap-[14rem]">
          <TabsTrigger value="addCategory">Add Credits</TabsTrigger>
          <TabsTrigger value="view-pack">View User Packs</TabsTrigger>
        </TabsList>
        <TabsContent value="addCategory">
          <FormProvider
            methods={methods}
            onSubmit={handleSubmit(handleAddCredits)}
          >
            <div className="max-50 space-y-6">
              <AutoCompleteCommon
                name="userId"
                error={errors.userId}
                label="Choose a User:"
                listData={
                  users.length > 0
                    ? users?.map((user: any) => ({
                        value: user?.id,
                        name: user?.email,
                      }))
                    : []
                }
                value={getValues("userId")}
                // defaultValue={{ value: getValues("userId") }}
                placeholder="Choose a User"
                selectedDropDownVal={(val: any) => {
                  setValue("userId", val);
                  trigger("userId");
                  const selectedData: any = users?.find(
                    (item: any) => item?.id === val
                  );
                  selectedData
                    ? setTotalCredits(selectedData?.credits)
                    : setTotalCredits(totalCredit);
                }}
              />
              <FormTextField
                type="number"
                name="credits"
                label="credits amount"
                placeholder="credits amount"
              />
              <FormSelectField
                placeholder="Choose Provider"
                label="Choose Provider :"
                name="provider"
                option={providers?.map((item) => ({
                  value: item.provider,
                  name: item.provider,
                }))}
              />
              <AutoCompleteCommon
                disabled={getValues("provider") === ""}
                error={errors?.model}
                label="Model:"
                listData={providers.flatMap((item: any) =>
                  item?.provider === getValues("provider")
                    ? item?.models?.map((subModel: any) => ({
                        value: subModel,
                        name: subModel,
                      }))
                    : []
                )}
                value={getValues("model")}
                // defaultValue={{ value: getValues("model") }}
                placeholder="Select Model"
                selectedDropDownVal={(val: any) => {
                  setValue("model", val);
                  trigger("model");
                }}
              />{" "}
              <div className="mt-10 flex items-center justify-between">
                <button
                  style={{ margin: "0px" }}
                  className="btn-black"
                  type="submit"
                >
                  Give credits to user
                </button>
              </div>
            </div>
            <ToastContainer />
          </FormProvider>
        </TabsContent>
        <TabsContent value="view-pack" className="p-[30px]">
          <ViewAllUserPacks />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default CreditsToUser;
