import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../../common/hook-form/FormProvider";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import FormTextAreaField from "../../common/hook-form/FormTextAreaField";
import {
  addCreditItemToUser,
  addPerkToUser,
} from "../../services/perk.services";
import AutoCompleteCommon from "../../common/CommonAutoComplete";

/**
 * The AddPerk component represents a form for adding a perk.
 * It allows the user to select a perk and choose a character to assign the perk to.
 */
interface Perks {
  // Define the properties of a perk
  id: string | undefined | number;
  title: string;
  description: string;
  value: number | string;
  type?: string;
}
interface User {
  id: string;
  first_name: string;
  last_name: string;
  username: string;
  email?: string;
}

interface IProps {
  perks: Perks[];
  users: User[];
}
interface Perk {
  userId: string;
  perkId: string | undefined | number;
}
const AddPerk: React.FC<IProps> = ({ perks, users }) => {
  const [perk, setPerk] = useState<Partial<Perk>>({
    userId: "",
    perkId: "",
  });
  const [credits, setCredits] = useState<number>(0);

  const methods: any = useForm<any>({
    resolver: yupResolver(
      Yup.object().shape({
        perkId: Yup.string().required(""),
        userId: Yup.string().required(""),
      })
    ),
    defaultValues: {
      perkId: "",
      userId: "",
    },
  });

  const {
    handleSubmit,
    reset,
    setValue,
    getValues,
    trigger,
    formState: { errors },
  } = methods;

  // const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const { name, value } = event.target;
  //   setPerk((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  const addHandler = (formData: Perk) => {
    addPerkToUser(formData)
      .then((res) => {
        toast.success("Added Successfully");
        reset();
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };

  // click on button give credits
  // const handleAddCredits: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  //   e.preventDefault();

  //   const userId = perk.userId;
  //   const creditsAmount = credits;

  //   if (!userId || userId === "Choose a User") {
  //     toast.error("Please select a user");
  //     return;
  //   }

  //   if (!creditsAmount || creditsAmount < 0) {
  //     toast.error("Please enter a valid amount");
  //     return;
  //   }

  //   addCreditItemToUser(userId, creditsAmount)
  //     .then((res) => {
  //       toast.success("Added Successfully");
  //       setCredits(0);
  //     })
  //     .catch((err) => {
  //       toast.error(err.response.data.message);
  //     });
  // };
  const selectedPerkHandler = (val: string | number) => {
    setValue("perkId", val);
    trigger("perkId");
  };
  return (
    <>
      <ToastContainer />
      <FormProvider methods={methods} onSubmit={handleSubmit(addHandler)}>
        <div className="max-50 space-y-4">
          <AutoCompleteCommon
            error={errors.perkId}
            label="Select perk:"
            listData={
              perks.length > 0
                ? perks?.map((perk) => ({
                    value: perk.id,
                    name: perk.title,
                  }))
                : []
            }
            value={getValues("perkId")}
            placeholder="Select perk"
            selectedDropDownVal={selectedPerkHandler}
          />
          <AutoCompleteCommon
            error={errors.userId}
            label="Choose a User:"
            listData={
              users.length > 0
                ? users?.map((user) => ({
                    value: user.id,
                    name: user.email,
                  }))
                : []
            }
            value={getValues("userId") as String}
            // defaultValue={{ value: getValues("userId") }}
            placeholder="Choose a User"
            selectedDropDownVal={(val: any) => {
              setValue("userId", val);
              trigger("userId");
            }}
          />
        </div>
        <button className="btn-black mt-5" type="submit">
          Add
        </button>
      </FormProvider>

      {/* <hr />
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="number"
            placeholder="credits amount"
            value={credits}
            style={{
              maxWidth: "20%",
              // margin: "0 auto",
              display: "block",
              marginBottom: "20px",
              fontSize: "40px",
            }}
            onChange={(e) => setCredits(Number(e.target.value))}
          />
          <Form.Text
            className="text-muted"
            style={{
              // maxWidth: "20%",
              margin: "0 auto",
              display: "block",
              marginBottom: "20px",
              fontSize: "20px",
            }}
          ></Form.Text>
        </Form.Group>
        <Button  type="submit" onClick={handleAddCredits}>
          Give credits to user
        </Button>
      </Form> */}
    </>
  );
};

export default AddPerk;
